import React,{useState,useEffect} from 'react';
import {NavLink,useNavigate} from "react-router-dom";
import { useForm } from 'react-hook-form';
import AdminHeader from '../layout/AdminHeader';
import AdminSidebar from '../layout/AdminSidebar';
import AdminFooter from '../layout/AdminFooter';
import common from '../services/common' 
import { Store } from 'react-notifications-component';
import Config from '../config/config';
import { Button, Modal } from 'react-bootstrap'
import axios from 'axios';
import {  useParams } from "react-router-dom";



const ChildAbuseFile = ({ userDataval,childupdateDataval,setchildupdateDataval,childformDataval,ddshow,onClose,setchildformDataval}) => {
  
  const params = useParams();

  if(userDataval?.role_id!=4)
  var candidate_id_main = params.id;
else
var candidate_id_main = userDataval?.candidate_id;
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
      applicant_name: "",
      dob: "",
      former_name: "",
      ssn: "",
      abuse_investigation: "",
      employment_sepration: "",
      license_revocation: "",
        agreeToTerms: false,
      });
    const [showModal, setShowModal] = useState(ddshow); // Modal state
    useEffect(() => {
        setShowModal(ddshow);
        console.log("kannanvaldata",childformDataval)
        setFormData(childformDataval)
    },
    [ddshow,childformDataval])
    const ddhandleClose = () => {
        setShowModal(false);
      };
      const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
          ...formData,
          [name]: type === "checkbox" ? checked : value,
        });
      };
      const handleSubmit = async (e) => {
        e.preventDefault();
      
        // Handle form submission logic here
        let formErrors = {};
        const ssnRegex = /^[0-9]{4}$/;
        if (!formData?.applicant_name) {
          formErrors.applicant_name = 'Applicant Name is required';
        }
        if (!formData?.dob) {
          formErrors.dob = 'Date of Birth is required';
        }
        if (!formData?.ssn ){
          formErrors.ssn = 'Last 4 digits of SSN is required';
        }
        if (formData?.ssn && !ssnRegex?.test(formData.ssn)) {
          formErrors.ssn = 'Last 4 digits of SSN must be a number';
        }
        if (!formData?.abuse_investigation  && formData?.abuse_investigation!= "0" && formData?.abuse_investigation!= "1" ) {
          formErrors.abuse_investigation = 'Please select an option for child abuse investigation';
        }
        if (!formData?.employment_sepration  && formData?.employment_sepration!= "0" && formData?.employment_sepration!= "1" ) {
          formErrors.employment_sepration = 'Please select an option for employment separation';
        }
      
        if (!formData?.license_revocation  && formData?.license_revocation!= "0" && formData?.license_revocation!= "1" ) {
          formErrors.license_revocation = 'Please select an option for license revocation';
        }

     
    if (!formData?.applicant_sign) {
      formErrors.applicant_sign = 'Signature is required';
    }

    if (!formData?.date) {
      formErrors.date = 'Date is required';
    }

        
        
   
       console.log("kannanerror", formErrors.length)
       setErrors(formErrors);
       if (Object.keys(formErrors).length === 0) {
        try {
            // Submit the form data using Axios candidate_id_main
            var user_data = localStorage.getItem("user_data");
      var user_data_json = JSON.parse(user_data);
      var authheaders = {
        "Content-Type": "text/json",
        "Authorization": "Bearer " + user_data_json?.token

      }
            formData.candidate_detail_id=candidate_id_main
            const response = await axios.post(Config.API_URL + 'api/add-abuse-discloser',formData,{
              headers: authheaders
            });
            setLoading(false);
            setchildupdateDataval(2)
            console.log('Form submitted successfully:', response.data);
            onClose();
            if (response?.data?.status == 202) {
              Store.addNotification({
                  title: "Success!",
                  message: response?.data?.message,
                  type: "success",
                  container: "top-right",
                  animationIn: ["animate__animated", "animate__fadeIn"],
                  animationOut: ["animate__animated", "animate__fadeOut"],
                  dismiss: {
                      duration: 3000,
                      onScreen: true
                  }
              });
              console.log("gnaneshin",childupdateDataval)
              return
            }
            else{
              Store.addNotification({
                title: "Error!",
                message: response?.data?.message,
                type: "danger",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });
            }
            // You can add additional logic here, like resetting the form or displaying a success message
        } catch (error) {
            console.error('Error submitting the form:', error);
            // Handle error response if needed
        }
    } else {
        console.log('Form contains errors:', formErrors);
    }

      };
  return (
    
    <Modal show={showModal} onHide={onClose} dialogClassName="custom-dialog">
    <Modal.Header closeButton>
        <Modal.Title> <h4>Sexual Misconduct/Child Abuse Disclosure Release  </h4></Modal.Title>
    </Modal.Header>
    <Modal.Body>
    <form>
   

      <section>
        <p>
          Applicants must complete the below form in compliance with the New
          Jersey (P.L. 2018, c. 5) and Maryland (Section 6-113.2 of the
          education article code of Maryland) state laws. These laws prohibit a
          hiring entity from employing a person serving in a position which
          involves regular contact with students unless the hiring entity
          conducts a review of the employment history of the applicant by
          contacting former and current employers and requesting information
          regarding child abuse and misconduct.
        </p>
       
      </section>

      <h2>Section 1. Applicant Certification and Release</h2>

      <div className="form-group">
       <strong> <label >Applicant Name (First, Middle, Last):</label></strong>
        <input
          type="text"
          name="applicant_name"
          value={formData?.applicant_name}
          onChange={handleChange}
          required
          style={{ marginLeft:"20px" }}
        />
                {errors.applicant_name && <p style={{color: 'red'}}>{errors.applicant_name}</p>}

      </div>

      <div className="form-group">
        <label>Date of Birth:</label>
        <input
          type="date"
          name="dob"
          value={formData?.dob}
          onChange={handleChange}
          required
          style={{ marginLeft:"139px" }}
        />
                {errors.dob && <p style={{color: 'red'}}>{errors.dob}</p>}

      </div>

      <div className="form-group">
        <label>Any Former Names (if applicable):</label>
        <input
          type="text"
          name="former_name"
          value={formData?.former_name}
          onChange={handleChange}
          style={{ marginLeft:"30px" }}
        />
      </div>

      <div className="form-group">
        <label>Last 4 Digits of Social Security Number:</label>
        <input
          type="text"
          name="ssn"
          value={formData?.ssn}
          onChange={handleChange}
          maxLength={4}
          required
        />
                {errors.ssn && <p style={{color: 'red'}}>{errors.ssn}</p>}

      </div>

      <div style={{marginTop:"20px" , marginBottom:"20px"}}>
        <label>
          Have you ever been the subject of any child abuse or sexual misconduct
          investigation by any employer, state licensing agency, law enforcement
          agency, Department of Children and Families, or any child protective
          services (unless the investigation resulted in a finding that the
          allegations were false or the alleged incident of child abuse or
          sexual misconduct was not substantiated)?
        </label>
        <label style={{marginLeft:"30px"}}>
          <input
            type="radio"
            name="abuse_investigation"
            value="1"
            checked={formData?.abuse_investigation == "1"}
            onChange={handleChange}
            required
          />{" "}
          Yes
        </label>
        <label style={{marginLeft:"30px"}}>
          <input
            type="radio"
            name="abuse_investigation"
            value="0"
            checked={formData?.abuse_investigation == "0"}
            onChange={handleChange}
            required
          />{" "}
          No
        </label>
        {errors.abuse_investigation && <p style={{color: 'red'}}>{errors.abuse_investigation}</p>}

      </div>

      <div style={{marginTop:"20px" , marginBottom:"20px"}}>
        <label >
          Have you ever been disciplined, discharged, non-renewed, asked to
          resign from employment, resigned from, or otherwise separated from any
          employment (1) while allegations of child abuse or sexual misconduct
          were pending or under investigation, or (2) due to an adjudication or
          finding of child abuse or sexual misconduct?
        </label>
        <label style={{marginLeft:"30px"}}>
          <input
            type="radio"
            name="employment_sepration"
            value="1"
            checked={formData?.employment_sepration == "1"}
            onChange={handleChange}
            required
          />{" "}
          Yes
        </label>
        <label style={{marginLeft:"30px"}}>
          <input
            type="radio"
            name="employment_sepration"
            value="0"
            checked={formData?.employment_sepration == "0"}
            onChange={handleChange}
            required
          />{" "}
          No
        </label>
        {errors.employment_sepration && (
    <p style={{ color: "red" }}>{errors.employment_sepration}</p>
  )}

      </div>

      <div style={{marginTop:"20px" , marginBottom:"20px"}}>
        <label>
          Has a license, professional license, or certificate been suspended,
          surrendered, or revoked (1) while allegations of child abuse or sexual
          misconduct were pending or under investigation, or (2) due to an
          adjudication or finding of child abuse or sexual misconduct?
        </label>
        <label style={{marginLeft:"30px"}}>
          <input
            type="radio"
            name="license_revocation"
            value="1"
            checked={formData?.license_revocation == "1"}
            onChange={handleChange}
            required
          />{" "}
          Yes
        </label>
        <label style={{marginLeft:"30px"}}>
          <input
            type="radio"
            name="license_revocation"
            value="0"
            checked={formData?.license_revocation == "0"}
            onChange={handleChange}
            required
          />{" "}
          No
        </label>
        {errors.license_revocation && (
    <p style={{ color: "red" }}>{errors.license_revocation}</p>
  )}

      </div>

    
      <section>
      
        <p>
          By signing this form, I understand that if I provide false
          information or willfully fail to disclose material information
          required by this form, I will be subject to professional discipline up
          to and including termination and denial of employment, and any other
          criminal or civil penalties in accordance with state laws and
          regulations. I hereby authorize Progressive Option Support Services
          to release the requested information, and any other information
          permitted by law, to the current or former employees I provided via
          Google forms during onboarding. I release, waive, and discharge
          Progressive Option Support Services and former entity from all
          liability of any kind that may arise from the disclosure and use of
          the information provided on this form.
        </p>
      </section>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <label>Applicant Name:</label>
          <input
            type="text"
            name="applicant_sign"
            value={formData?.applicant_sign}
            onChange={handleChange}
            required
          />
          
                    {errors.applicant_sign && <p style={{color: 'red'}}>{errors.applicant_sign}</p>}

        </div>
        <div>
          <label>Date:</label>
          <input
            type="date"
            name="date"
            value={formData?.date}
            onChange={handleChange}
            required
          />
                    {errors.date && <p style={{color: 'red'}}>{errors.date}</p>}

        </div>
      </div>
    </form>


    </Modal.Body>
    <Modal.Footer>
        <button type="button" className="btn btn-primary" form='directdeposit_candidate'  onClick={handleSubmit}  >Save</button>
        <span className="btn btn-default" onClick={onClose} >Cancel</span>

    </Modal.Footer>
</Modal>
      
    );
};

export default ChildAbuseFile
