import React, { Component, useRef, useState, useEffect } from 'react';

import Config from "../config/config";
import axios from "axios";
 

 



var headers = {
  "Content-Type": "text/json",
  "Authorization":"Bearer "+Config.API_AUTH
}

 
 
 


 

export default {



  axiosDownloadFile(url) {
// alert(url)
    var fileName = url.split('/').pop()
    return axios({
      url,
      method: 'GET',
      responseType: 'blob',
    })
      .then(response => {
        const href = window.URL.createObjectURL(response.data);
  
        const anchorElement = document.createElement('a');
  
        anchorElement.href = href;
        anchorElement.download = fileName;
  
        document.body.appendChild(anchorElement);
        anchorElement.click();
  
        document.body.removeChild(anchorElement);
        window.URL.revokeObjectURL(href);
      })
      .catch(error => {
        console.log('error: ', error);
      });
  },
  

  getdropdown: async (body) => {


    const result = await axios.get(Config.API_URL + 'api/getalldata', {
      headers: headers
    });
    return result;
  },
  getmenus: async (body) => {


    const result = await axios.get(Config.API_URL + 'api/getallmenu', {
      headers: headers
    });
    return result;
  },
  getfieldsettings: async (body) => {

    let config = {
      headers: headers,
      params: {
        ...body
      },
    }

    const result = await axios.get(Config.API_URL + 'api/getfieldsettings', config );
    return result;
  },



  register: async (userData) => {
    try {
      const response = await axios.post(Config.API_URL + 'api/register', userData, {
        headers: headers
      });

      return response;

    } catch (error) {

      return error;

    }
  },
  forgetpassword: async (userData) => {
    try {
      const response = await axios.post(Config.API_URL + 'api/password/email', userData, {
        headers: headers
      });

      return response;

    } catch (error) {

      return error;

    }
  },


  resetpassword: async (userData) => {
    try {
      const response = await axios.post(Config.API_URL + 'api/password/reset', userData, {
        headers: headers
      });

      return response;

    } catch (error) {

      return error;

    }
  },


  login: async (userData) => {
    try {
      const response = await axios.post(Config.API_URL + 'api/login', userData, {
        headers: headers
      });

      return response;

    } catch (error) {

      return error;

    }
  },
  dashboard: async () => {

     

    try {
      var user_data=localStorage.getItem("user_data");     
      var user_data_json=JSON.parse(user_data);  

      console.log(user_data_json);
      
       
    var authheaders = {
        "Content-Type": "text/json",
        "Authorization": "Bearer "+user_data_json?.token
     
    } 
      
   

      const response = await axios.get(Config.API_URL + 'api/dashboard',{
        headers: authheaders
      });

       



      return response;

    } catch (error) {

      return error;

    }

  },

  getportaluserlist: async (data) => {
    try {

      var user_data=localStorage.getItem("user_data");     
      var user_data_json=JSON.parse(user_data);    
    var authheaders = {
        "Content-Type": "text/json",
        "Authorization":"Bearer "+user_data_json?.token
     
    }
    
    let config = {
      headers: authheaders,
      params: {
        ...data
      },
    }

    
      const response = await axios.get(Config.API_URL + 'api/get-all-user',config);

      return response;

    } catch (error) {

      return error;

    }
  },


  getdeposituserlist: async (data) => {
    try {

      var user_data=localStorage.getItem("user_data");     
      var user_data_json=JSON.parse(user_data);    
    var authheaders = {
        "Content-Type": "text/json",
        "Authorization":"Bearer "+user_data_json?.token
     
    }
    
    let config = {
      headers: authheaders,
      params: {
        ...data
      },
    }

    
      const response = await axios.get(Config.API_URL + 'api/get-deposit',config);

      return response;

    } catch (error) {

      return error;

    }
  },


  getlocationlist: async (data) => {
    try {

      var user_data=localStorage.getItem("user_data");     
      var user_data_json=JSON.parse(user_data);    
    var authheaders = {
        "Content-Type": "text/json",
        "Authorization":"Bearer "+user_data_json?.token
     
    }
    
    let config = {
      headers: authheaders,
      params: {
        ...data
      },
    }

    
      const response = await axios.get(Config.API_URL + 'api/get-locations',config);

      return response;

    } catch (error) {

      return error;

    }
  },

  getportalpositionlist: async (data) => {
    try {

      var user_data=localStorage.getItem("user_data");     
      var user_data_json=JSON.parse(user_data);    
    var authheaders = {
        "Content-Type": "text/json",
        "Authorization":"Bearer "+user_data_json?.token
     
    }
    
    let config = {
      headers: authheaders,
      params: {
        ...data
      },
    }

    
      const response = await axios.get(Config.API_URL + 'api/get-position',config);

      return response;

    } catch (error) {

      return error;

    }
  },

  getpayIncreaselist: async (data) => {
    try {

      var user_data=localStorage.getItem("user_data");     
      var user_data_json=JSON.parse(user_data);    
    var authheaders = {
        "Content-Type": "text/json",
        "Authorization":"Bearer "+user_data_json?.token
     
    }
    
    let config = {
      headers: authheaders,
      params: {
        ...data
      },
    }

    
      const response = await axios.get(Config.API_URL + 'api/get-all-pay-list',config);

      return response;

    } catch (error) {

      return error;

    }
  },

  updateportalposition: async (id, status) => {
    try {

      var user_data=localStorage.getItem("user_data");     
      var user_data_json=JSON.parse(user_data);    
    var authheaders = {
        "Content-Type": "text/json",
        "Authorization":"Bearer "+user_data_json?.token
     
    }
    
    let config = {
      headers: authheaders,
    }

    
      const response = await axios.get(Config.API_URL + 'api/update-position?position_id='+id+'&status='+status,config);

      return response;

    } catch (error) {

      return error;

    }
  },





  AddorupdatePortalUser: async (userData) => {
    try {

      var user_data=localStorage.getItem("user_data");     
      var user_data_json=JSON.parse(user_data);    
    var authheaders = {
        "Content-Type": "text/json",
        "Authorization": "Bearer "+user_data_json?.token
     
    } 
      const response = await axios.post(Config.API_URL + 'api/add-user', userData, {
        headers: authheaders
      });

      return response;

    } catch (error) {

      return error;

    }
  },

  AddorupdatePayIncrease: async (userData) => {
    try {

      var user_data=localStorage.getItem("user_data");     
      var user_data_json=JSON.parse(user_data);    
    var authheaders = {
        "Content-Type": "text/json",
        "Authorization": "Bearer "+user_data_json?.token
     
    } 
      const response = await axios.post(Config.API_URL + 'api/add-pay-increase', userData, {
        headers: authheaders
      });

      return response;

    } catch (error) {

      return error;

    }
  },

  AddorupdatePosition: async (userData) => {
    try {

      var user_data=localStorage.getItem("user_data");     
      var user_data_json=JSON.parse(user_data);    
    var authheaders = {
        "Content-Type": "text/json",
        "Authorization": "Bearer "+user_data_json?.token
     
    } 
      const response = await axios.post(Config.API_URL + 'api/add-position', userData, {
        headers: authheaders
      });

      return response;

    } catch (error) {

      return error;

    }
  },

  AddPositionLocation: async (userData) => {
    try {
      var user_data=localStorage.getItem("user_data");     
      var user_data_json=JSON.parse(user_data);    
      var authheaders = {
          "Content-Type": "text/json",
          "Authorization": "Bearer "+user_data_json?.token
      
      }
      const response = await axios.post(Config.API_URL + 'api/add-position-location', userData, {
        headers: authheaders
      });
      return response;
    } catch (error) {
      return error;
    }
  },

  deletePositionlocation: async (userData) => {
    try {
      var user_data=localStorage.getItem("user_data");     
      var user_data_json=JSON.parse(user_data);    
      var authheaders = {
          "Content-Type": "text/json",
          "Authorization": "Bearer "+user_data_json?.token
      
      }
      const response = await axios.post(Config.API_URL + 'api/update-position-location', userData, {
        headers: authheaders
      });
      return response;
    } catch (error) {
      return error;
    }
  },

  AddorupdateLocation: async (userData) => {
    try {

      var user_data=localStorage.getItem("user_data");     
      var user_data_json=JSON.parse(user_data);    
    var authheaders = {
        "Content-Type": "text/json",
        "Authorization": "Bearer "+user_data_json?.token
     
    } 
      const response = await axios.post(Config.API_URL + 'api/addlocation', userData, {
        headers: authheaders
      });

      return response;

    } catch (error) {

      return error;

    }
  },

  edituser: async (user_id) => {
    try {

      var user_data=localStorage.getItem("user_data");     
      var user_data_json=JSON.parse(user_data);    
    var authheaders = {
        "Content-Type": "text/json",
        "Authorization": "Bearer "+user_data_json?.token
     
    } 


  


  let config = {
    headers: authheaders,
    params: {
      user_id
    },
  }
      const response = await axios.get(Config.API_URL + 'api/edit-user', config );

      return response;

    } catch (error) {

      return error;

    }
  },

  editpayincrease: async (pay_id) => {
    try {

      var user_data=localStorage.getItem("user_data");     
      var user_data_json=JSON.parse(user_data);    
    var authheaders = {
        "Content-Type": "text/json",
        "Authorization": "Bearer "+user_data_json?.token
     
    } 


  


  let config = {
    headers: authheaders,
    params: {
      pay_id
    },
  }
      const response = await axios.get(Config.API_URL + 'api/edit-pay-increase', config );

      return response;

    } catch (error) {

      return error;

    }
  },

  getcandidatepayRcd: async (pay_id) => {
    try {

      var user_data=localStorage.getItem("user_data");     
      var user_data_json=JSON.parse(user_data);    
    var authheaders = {
        "Content-Type": "text/json",
        "Authorization": "Bearer "+user_data_json?.token
     
    } 


  


  let config = {
    headers: authheaders,
    params: {
      pay_id
    },
  }
      const response = await axios.get(Config.API_URL + 'api/get-paycandidate-record', config );

      return response;

    } catch (error) {

      return error;

    }
  },


  editposition: async (position_id) => {
    try {

      var user_data=localStorage.getItem("user_data");     
      var user_data_json=JSON.parse(user_data);    
    var authheaders = {
        "Content-Type": "text/json",
        "Authorization": "Bearer "+user_data_json?.token
     
    } 


  


  let config = {
    headers: authheaders,
    params: {
      position_id
    },
  }
      const response = await axios.get(Config.API_URL + 'api/edit-position', config );

      return response;

    } catch (error) {

      return error;

    }
  },



  editlocation: async (location_id) => {
    try {

      var user_data=localStorage.getItem("user_data");     
      var user_data_json=JSON.parse(user_data);    
    var authheaders = {
        "Content-Type": "text/json",
        "Authorization": "Bearer "+user_data_json?.token
     
    } 


  


  let config = {
    headers: authheaders,
    params: {
      location_id
    },
  }
      const response = await axios.get(Config.API_URL + 'api/edit-location', config );

      return response;

    } catch (error) {

      return error;

    }
  },

  deleteuser: async (userData) => {
    try {
      var user_data=localStorage.getItem("user_data");     
      var user_data_json=JSON.parse(user_data);    
    var authheaders = {
        "Content-Type": "text/json",
        "Authorization": "Bearer "+user_data_json?.token
     
    } 
      const response = await axios.post(Config.API_URL + 'api/delete-user', userData, {
        headers: authheaders
      });

      return response;

    } catch (error) {

      return error;

    }
  },

  deletelocation: async (userData) => {
    try {
      var user_data=localStorage.getItem("user_data");     
      var user_data_json=JSON.parse(user_data);    
    var authheaders = {
        "Content-Type": "text/json",
        "Authorization": "Bearer "+user_data_json?.token
     
    } 
      const response = await axios.post(Config.API_URL + 'api/delete-location', userData, {
        headers: authheaders
      });

      return response;

    } catch (error) {

      return error;

    }
  },

  editapplication: async (applicantid, istrue = 0) => {
    try {

      var user_data=localStorage.getItem("user_data");     
      var user_data_json=JSON.parse(user_data);    
    var authheaders = {
        "Content-Type": "text/json",
        "Authorization": "Bearer "+user_data_json?.token
     
    } 


    let config = {
      headers: authheaders,
      params: {
        applicantid,
        istrue
      },
    }

      const response = await axios.get(Config.API_URL + 'api/edit-applicant',config);

      return response;

    } catch (error) {

      return error;

    }
  },


  getEmailContent: async (applicantid, type) => {
    try {

      var user_data=localStorage.getItem("user_data");     
      var user_data_json=JSON.parse(user_data);    
    var authheaders = {
        "Content-Type": "text/json",
        "Authorization": "Bearer "+user_data_json?.token
     
    } 


    let config = {
      headers: authheaders,
      params: {
        applicantid,
        type
      },
    }

      const response = await axios.get(Config.API_URL + 'api/get-email-content',config);

      return response;

    } catch (error) {

      return error;

    }
  },

  getContractContent: async (applicantid, position_id) => {
    try {

      var user_data=localStorage.getItem("user_data");     
      var user_data_json=JSON.parse(user_data);    
    var authheaders = {
        "Content-Type": "text/json",
        "Authorization": "Bearer "+user_data_json?.token
     
    } 


    let config = {
      headers: authheaders,
      params: {
        applicantid,
        position_id
      },
    }

      const response = await axios.get(Config.API_URL + 'api/get-contract-content',config);

      return response;

    } catch (error) {

      return error;

    }
  },
  getcandidatelist: async (data) => {
    try {

      var user_data=localStorage.getItem("user_data");     
      var user_data_json=JSON.parse(user_data);    
    var authheaders = {
        "Content-Type": "text/json",
        "Authorization":"Bearer "+user_data_json?.token
     
    }
    
    let config = {
      headers: authheaders,
      params: {
        ...data
      },
    }

    
      const response = await axios.get(Config.API_URL + 'api/applicant-list',config);

      return response;

    } catch (error) {

      return error;

    }
  },

  getcandidatepipline: async (position_id, location_id) => {
    try {

      var user_data=localStorage.getItem("user_data");     
      var user_data_json=JSON.parse(user_data);    
    var authheaders = {
        "Content-Type": "text/json",
        "Authorization":"Bearer "+user_data_json?.token
     
    }
    
    let config = {
      headers: authheaders,
      params: {
        position_id,
        location_id
      },
    }

    
      const response = await axios.get(Config.API_URL + 'api/get-candidate-pipline',config);

      return response;

    } catch (error) {

      return error;

    }
  },

  getpositionstatus: async (position_id) => {
    try {

      var user_data=localStorage.getItem("user_data");     
      var user_data_json=JSON.parse(user_data);    
    var authheaders = {
        "Content-Type": "text/json",
        "Authorization":"Bearer "+user_data_json?.token
     
    }
    
    let config = {
      headers: authheaders,
      params: {
        position_id
      },
    }

    
      const response = await axios.get(Config.API_URL + 'api/get-position-status',config);

      return response;

    } catch (error) {

      return error;

    }
  },

  updatecandidatestatus: async (userData) => {
    try {
      var user_data=localStorage.getItem("user_data");     
      var user_data_json=JSON.parse(user_data);    
    var authheaders = {
        "Content-Type": "text/json",
        "Authorization": "Bearer "+user_data_json?.token
     
    } 
      const response = await axios.post(Config.API_URL + 'api/update-candidate-status', userData, {
        headers: authheaders
      });

      return response;

    } catch (error) {

      return error;

    }
  },

  updatepositionstatus: async (itemId, status) => {
    try {
      var user_data=localStorage.getItem("user_data");     
      var user_data_json=JSON.parse(user_data);    
    var authheaders = {
        "Content-Type": "text/json",
        "Authorization": "Bearer "+user_data_json?.token
     
    } 
      const response = await axios.get(Config.API_URL + 'api/update-position-status?id='+itemId+'&status='+status, {
        headers: authheaders
      });

      return response;

    } catch (error) {

      return error;

    }
  },

  updatecandidate: async (userData) => {
    try {

      var user_data=localStorage.getItem("user_data");     
      var user_data_json=JSON.parse(user_data);    
    var authheaders = {
        "Content-Type": "text/json",
        "Authorization": "Bearer "+user_data_json?.token
     
    } 
      const response = await axios.post(Config.API_URL + 'api/store-employee', userData, {
        headers: authheaders
      });

      return response;

    } catch (error) {

      return error;

    }
  },

  updatecandidateDocuments: async (userData) => {
    try {

      var user_data=localStorage.getItem("user_data");     
      var user_data_json=JSON.parse(user_data);    
    var authheaders = {
        "Content-Type": "text/json",
        "Authorization": "Bearer "+user_data_json?.token
     
    } 
      const response = await axios.post(Config.API_URL + 'api/update-documents', userData, {
        headers: authheaders
      });

      return response;

    } catch (error) {

      return error;

    }
  },

  updateApprovedContract: async (userData) => {
    try {

      var user_data=localStorage.getItem("user_data");     
      var user_data_json=JSON.parse(user_data);    
    var authheaders = {
        "Content-Type": "text/json",
        "Authorization": "Bearer "+user_data_json?.token
     
    } 
      const response = await axios.post(Config.API_URL + 'api/make-approved-contract', userData, {
        headers: authheaders
      });

      return response;

    } catch (error) {

      return error;

    }
  },


  addorupdatecandidatedeposit: async (userData) => {
    try {

      var user_data=localStorage.getItem("user_data");     
      var user_data_json=JSON.parse(user_data);    
    var authheaders = {
        "Content-Type": "text/json",
        "Authorization": "Bearer "+user_data_json?.token
     
    } 
      const response = await axios.post(Config.API_URL + 'api/add-deposit', userData, {
        headers: authheaders
      });

      return response;

    } catch (error) {

      return error;

    }
  },

  addorupdatetaxformi9: async (userData) => {
    try {

      var user_data=localStorage.getItem("user_data");     
      var user_data_json=JSON.parse(user_data);    
    var authheaders = {
        "Content-Type": "text/json",
        "Authorization": "Bearer "+user_data_json?.token
     
    } 
      const response = await axios.post(Config.API_URL + 'api/add-eligiblity-certificate', userData, {
        headers: authheaders
      });

      return response;

    } catch (error) {

      return error;

    }
  },

  addorupdatetaxformw4: async (userData) => {
    try {

      var user_data=localStorage.getItem("user_data");     
      var user_data_json=JSON.parse(user_data);    
    var authheaders = {
        "Content-Type": "text/json",
        "Authorization": "Bearer "+user_data_json?.token
     
    } 
      const response = await axios.post(Config.API_URL + 'api/add-holding-certificate', userData, {
        headers: authheaders
      });

      return response;

    } catch (error) {

      return error;

    }
  },

  addorupdatetaxformnj: async (userData) => {
    try {

      var user_data=localStorage.getItem("user_data");     
      var user_data_json=JSON.parse(user_data);    
    var authheaders = {
        "Content-Type": "text/json",
        "Authorization": "Bearer "+user_data_json?.token
     
    } 
      const response = await axios.post(Config.API_URL + 'api/add-allowance-certificate', userData, {
        headers: authheaders
      });

      return response;

    } catch (error) {

      return error;

    }
  },

  addorupdatetaxformW9: async (userData) => {
    try {

      var user_data=localStorage.getItem("user_data");     
      var user_data_json=JSON.parse(user_data);    
    var authheaders = {
        "Content-Type": "text/json",
        "Authorization": "Bearer "+user_data_json?.token
     
    } 
      const response = await axios.post(Config.API_URL + 'api/add-w9', userData, {
        headers: authheaders
      });

      return response;

    } catch (error) {

      return error;

    }
  },

  addorupdatetaxformGenderInequity: async (userData) => {
    try {

      var user_data = localStorage.getItem("user_data");
      var user_data_json = JSON.parse(user_data);
      var authheaders = {
        "Content-Type": "text/json",
        "Authorization": "Bearer " + user_data_json?.token

      }
      const response = await axios.post(Config.API_URL + 'api/add-gender-inquity', userData, {
        headers: authheaders
      });

      return response;

    } catch (error) {

      return error;

    }
  },

  // IIC , Psychotherapy Eval Form
  addorupdateiicEvalform: async (userData) => {
    try {

      var user_data = localStorage.getItem("user_data");
      var user_data_json = JSON.parse(user_data);
      var authheaders = {
        "Content-Type": "text/json",
        "Authorization": "Bearer " + user_data_json?.token

      }
      const response = await axios.post(Config.API_URL + 'api/add-performance-iic', userData, {
        headers: authheaders
      });

      return response;

    } catch (error) {

      return error;

    }
  },
  

  addorupdatescorecard: async (userData) => {
    try {

      var user_data=localStorage.getItem("user_data");     
      var user_data_json=JSON.parse(user_data);    
    var authheaders = {
        "Content-Type": "text/json",
        "Authorization": "Bearer "+user_data_json?.token
     
    } 
      const response = await axios.post(Config.API_URL + 'api/add-scorecard', userData, {
        headers: authheaders
      });

      return response;

    } catch (error) {

      return error;

    }
  },

  addorupdateadditionalDoc: async (userData) => {
    try {

      var user_data=localStorage.getItem("user_data");     
      var user_data_json=JSON.parse(user_data);    
    var authheaders = {
        "Content-Type": "text/json",
        "Authorization": "Bearer "+user_data_json?.token
     
    } 
      const response = await axios.post(Config.API_URL + 'api/add-addtionaldoc', userData, {
        headers: authheaders
      });

      return response;

    } catch (error) {

      return error;

    }
  },


  editscorecard: async (score_card_id) => {
    try {

      var user_data=localStorage.getItem("user_data");     
      var user_data_json=JSON.parse(user_data);    
    var authheaders = {
        "Content-Type": "text/json",
        "Authorization": "Bearer "+user_data_json?.token
     
    } 


    let config = {
      headers: authheaders,
      params: {
        score_card_id
      },
    }

      const response = await axios.get(Config.API_URL + 'api/edit-scorecard',config);

      return response;

    } catch (error) {

      return error;

    }
  },

  editadditonalDoc: async (score_card_id) => {
    try {

      var user_data=localStorage.getItem("user_data");     
      var user_data_json=JSON.parse(user_data);    
    var authheaders = {
        "Content-Type": "text/json",
        "Authorization": "Bearer "+user_data_json?.token
     
    } 


    let config = {
      headers: authheaders,
      params: {
        score_card_id
      },
    }

      const response = await axios.get(Config.API_URL + 'api/edit-addtionaldoc',config);

      return response;

    } catch (error) {

      return error;

    }
  },


  getemployeelist: async (data) => {
    try {

      var user_data=localStorage.getItem("user_data");     
      var user_data_json=JSON.parse(user_data);    
    var authheaders = {
        "Content-Type": "text/json",
        "Authorization":"Bearer "+user_data_json?.token
     
    }
    
    let config = {
      headers: authheaders,
      params: {
        ...data
      },
    }

    
      const response = await axios.get(Config.API_URL + 'api/get-employee',config);

      return response;

    } catch (error) {

      return error;

    }
  },

  getAllEmployee: async (data) => {
    try {

      var user_data=localStorage.getItem("user_data");     
      var user_data_json=JSON.parse(user_data);    
    var authheaders = {
        "Content-Type": "text/json",
        "Authorization":"Bearer "+user_data_json?.token
     
    }
    
    let config = {
      headers: authheaders,
      params: {
        ...data
      },
    }

    
      const response = await axios.get(Config.API_URL + 'api/get-employee',config);

      return response;

    } catch (error) {

      return error;

    }
  },

  updateprofile: async (userData) => {
    try {

      var user_data=localStorage.getItem("user_data");     
      var user_data_json=JSON.parse(user_data);    
    var authheaders = {
        "Content-Type": "text/json",
        "Authorization": "Bearer "+user_data_json?.token
     
    } 
      const response = await axios.post(Config.API_URL + 'api/update-profile', userData, {
        headers: authheaders
      });

      return response;

    } catch (error) {

      return error;

    }
  },





};


