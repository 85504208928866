import React, { useState, useEffect } from 'react'; 
import { useForm } from 'react-hook-form';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import Config from '../config/config';
import {  useParams } from "react-router-dom";
import { Store } from 'react-notifications-component';

const RbtEvaluationForm = ({ userDataval, childupdateDataval, setchildupdateDataval, childformDataval, ddshow, onClose,setrbtevalformDataval,setrbtevalupdateDataval ,rbtevalformDataval}) => {
  
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [showModal, setShowModal] = useState(ddshow);
  const [employeeSignatureFile, setEmployeeSignatureFile] = useState(null);
  const [supervisorSignatureFile, setSupervisorSignatureFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({})
  useEffect(() => {
    setShowModal(ddshow);
    setFormData(rbtevalformDataval);
console.log("rbtevalformDataval",rbtevalformDataval)
  }, [ddshow,rbtevalformDataval]);
  const params = useParams();
  const onSubmit = async (data) => {
    console.log("register",data);
    try {
      var user_data = localStorage.getItem("user_data");
      var user_data_json = JSON.parse(user_data);
      var authheaders = {
        "Content-Type": "text/json",
        "Authorization": "Bearer " + user_data_json?.token

      }
      
    console.log("registerId",user_data_json);
    var candidate_id_main = params.id;
    data.candidate_detail_id=candidate_id_main;
    data.employee_sign=employeeSignatureFile;
    data.supervisor_sign=supervisorSignatureFile;

    console.log("registerId",data);
            // formData.candidate_detail_id=candidate_id_main
      const response = await axios.post(Config.API_URL + 'api/add-performance-bcb-rbt',data,{
        headers: authheaders
      });
      console.log('Form submitted successfully:', response.data);
         
      onClose(); // Close the modal after successful submission
      if (response?.data?.status == 202) {
        Store.addNotification({
            title: "Success!",
            message: response?.data?.message,
            type: "success",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 3000,
                onScreen: true
            }
        });
        //navigate('/admin/portal-users',{state:{success:'1'}});
        // window.location.reload();
        setLoading(false);
       setrbtevalupdateDataval(2)
   
        return;
      }
      else{
        Store.addNotification({
          title: "Error!",
          message: response?.data?.message,
          type: "danger",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
              duration: 3000,
              onScreen: true
          }
      });
      }
    }
    catch{

    }
    // Handle form submission
  };

  return (
    <form  style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>

    <Modal show={showModal} onHide={onClose} dialogClassName="custom-dialog">
      <Modal.Header closeButton>
        <Modal.Title>
          <h4>Employee Performance Evaluation</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
          <h2>Employee Performance Evaluation</h2>
          <div style={{ display: "flex", gap: "20px", marginBottom: "20px" }}>
        <div style={{ flex: 1 }}>
          <label>Employee Name and Title:</label>
          <input
            {...register("employee_name", { required: "Employee name is required" })}
            placeholder="Enter employee name"
            style={{ width: '100%' }}
            defaultValue={rbtevalformDataval?.employee_name}
          />
          {errors.employee_name && <p style={{ color: 'red' }}>{errors.employee_name.message}</p>}
        </div>

        <div style={{ flex: 1 }}>
          <label>Date:</label>
          <input
            type="date"
            {...register("date", { required: "Date is required" })}
            style={{ width: '100%' }}
            defaultValue={rbtevalformDataval?.date}
          />
          {errors.date && <p style={{ color: 'red' }}>{errors.date.message}</p>}
        </div>
      </div>

      {/* Second row with Supervisor Name and Department */}
      <div style={{ display: "flex", gap: "20px", marginBottom: "20px" }}>
        <div style={{ flex: 1 }}>
          <label>Supervisor Name and Title:</label>
          <input
            {...register("supervisor_name", { required: "Supervisor name is required" })}
            placeholder="Enter supervisor name"
            style={{ width: '100%' }}
            defaultValue={rbtevalformDataval?.supervisor_name}
          />
          {errors.supervisor_name && <p style={{ color: 'red' }}>{errors.supervisor_name.message}</p>}
        </div>

        <div style={{ flex: 1 }}>
          <label>Department:</label>
          <input
            {...register("department", { required: "Department is required" })}
            placeholder="Enter department"
            style={{ width: '100%' }}
            defaultValue={rbtevalformDataval?.department}
          />
          {errors.department && <p style={{ color: 'red' }}>{errors.department.message}</p>}
        </div>
      </div>

          

          <div>
            <p>
              <strong style={{  fontWeight: 'bold' }}>Direction:</strong> Rate the employee’s performance using the five-point scale listed below. 
              If an item does not apply or you do not have enough information to assign a rating, then leave the item blank. 
              Room for comments has been provided at the end of this form.
            </p>
          </div>

          {/* Performance Evaluation */}
          <h4>I.Overall Productivity / Job Knowledge</h4>
          <div>
            <label style={{ display: 'block', fontWeight: 'bold' }}>
              a. Employee is knowledgeable of his/her role:
            </label>
            <div style={{ marginLeft: '20px' }}>
              {[1, 2, 3, 4, 5].map(value => (
                <label key={value} style={{ marginLeft: '5px' }}>
                  <input
                    type="radio"
                    value={value}
                    {...register("job_knowledge_role", { required: "Please select a rating" })}
                    defaultChecked={rbtevalformDataval?.job_knowledge_role === value}

                  />
                  {value} - {["Poor", "NI", "Satisfactory", "Good", "Excellent"][value - 1]}
                </label>
              ))}
            </div>
            {errors.job_knowledge_role && <p style={{ color: 'red' }}>{errors.job_knowledge_role.message}</p>}
          </div>

          <div>
            <label style={{ display: 'block', fontWeight: 'bold' }}>
              b. Employee demonstrates the ability to apply and adhere to ethical and legal standards in clinical ABA therapy:
            </label>
            <div style={{ marginLeft: '20px' }}>
              {[1, 2, 3, 4, 5].map(value => (
                <label key={value} style={{ marginLeft: '5px' }}>
                  <input
                    type="radio"
                    value={value}
                    {...register("job_knowledge_therapy", { required: "Please select a rating" })}
                    defaultChecked={rbtevalformDataval?.job_knowledge_therapy === value}
                  />
                  {value} - {["Poor", "NI", "Satisfactory", "Good", "Excellent"][value - 1]}
                </label>
              ))}
            </div>
            {errors.job_knowledge_therapy && <p style={{ color: 'red' }}>{errors.job_knowledge_therapy.message}</p>}
          </div>

          <div>
            <label style={{ display: 'block', fontWeight: 'bold' }}>
              c. Employee maintains the confidentiality of client records and information:
            </label>
            <div style={{ marginLeft: '20px' }}>
              {[1, 2, 3, 4, 5].map(value => (
                <label key={value} style={{ marginLeft: '5px' }}>
                  <input
                    type="radio"
                    value={value}
                    {...register("job_knowledge_info", { required: "Please select a rating" })}
                    defaultChecked={rbtevalformDataval?.job_knowledge_info === value}
                  />
                  {value} - {["Poor", "NI", "Satisfactory", "Good", "Excellent"][value - 1]}
                </label>
              ))}
            </div>
            {errors.job_knowledge_info && <p style={{ color: 'red' }}>{errors.job_knowledge_info.message}</p>}
          </div>

          <div>
            <label style={{ display: 'block', fontWeight: 'bold' }}>
              d. Employee's interventions reflect knowledge of Applied Behavior Analysis techniques, dynamic, time-limited, crisis intervention, and systemic interventions:
            </label>
            <div style={{ marginLeft: '20px' }}>
              {[1, 2, 3, 4, 5].map(value => (
                <label key={value} style={{ marginLeft: '5px' }}>
                  <input
                    type="radio"
                    value={value}
                    {...register("job_knowledge_intervention", { required: "Please select a rating" })}
                    defaultChecked={rbtevalformDataval?.job_knowledge_intervention === value}
                  />
                  {value} - {["Poor", "NI", "Satisfactory", "Good", "Excellent"][value - 1]}
                </label>
              ))}
            </div>
            {errors.job_knowledge_intervention && <p style={{ color: 'red' }}>{errors.job_knowledge_intervention.message}</p>}
          </div>

          <div>
            <label style={{ display: 'block', fontWeight: 'bold' }}>
              e. Employee completes treatment plans/reports within a timely manner per company policy:
            </label>
            <div style={{ marginLeft: '20px' }}>
              {[1, 2, 3, 4, 5].map(value => (
                <label key={value} style={{ marginLeft: '5px' }}>
                  <input
                    type="radio"
                    value={value}
                    {...register("job_knowledge_policy", { required: "Please select a rating" })}
                    defaultChecked={rbtevalformDataval?.job_knowledge_policy === value}
                  />
                  {value} - {["Poor", "NI", "Satisfactory", "Good", "Excellent"][value - 1]}
                </label>
              ))}
            </div>
            {errors.job_knowledge_policy && <p style={{ color: 'red' }}>{errors.job_knowledge_policy.message}</p>}
          </div>

          <div>
            <label style={{ display: 'block', fontWeight: 'bold' }}>
              f. Employee responds to emails within 24 hours:
            </label>
            <div style={{ marginLeft: '20px' }}>
              {[1, 2, 3, 4, 5].map(value => (
                <label key={value} style={{ marginLeft: '5px' }}>
                  <input
                    type="radio"
                    value={value}
                    {...register("job_knowledge_email", { required: "Please select a rating" })}
                    defaultChecked={rbtevalformDataval?.job_knowledge_email === value}
                  />
                  {value} - {["Poor", "NI", "Satisfactory", "Good", "Excellent"][value - 1]}
                </label>
              ))}
            </div>
            {errors.job_knowledge_email && <p style={{ color: 'red' }}>{errors.job_knowledge_email.message}</p>}
          </div>

          <div>
            <label>Comments:</label>
            <textarea
              {...register("job_knowledge_comments")}
              placeholder="Add any additional comments about the employee's performance"
              style={{ width: '100%', height: '100px' }}
              defaultValue={rbtevalformDataval?.job_knowledge_comments}
            />
          </div>

          <h4>II. OVERALL WORK QUALITY</h4>
          <div>
            <label style={{ display: 'block', fontWeight: 'bold' }}>
            a1. Employee demonstrates the ability to modify ABA techniques, theories, and interventions to make them culturally appropriate for diverse populations (BCBA or BT).
            </label>
            <div style={{ marginLeft: '20px' }}>
              {[1, 2, 3, 4, 5].map(value => (
                <label key={value} style={{ marginLeft: '5px' }}>
                  <input
                    type="radio"
                    value={value}
                    {...register("work_quality_ability", { required: "Please select a rating" })}
                    defaultChecked={rbtevalformDataval?.work_quality_ability === value}
                  />
                  {value} - {["Poor", "NI", "Satisfactory", "Good", "Excellent"][value - 1]}
                </label>
              ))}
            </div>
            {errors.work_quality_ability && <p style={{ color: 'red' }}>{errors.work_quality_ability.message}</p>}
          </div>

          <div>
            <label style={{ display: 'block', fontWeight: 'bold' }}>
            a2. Employee's interventions reflect knowledge of ABA techniques, dynamic, time-limited, crisis intervention, and systemic interventions (BCBA).
            </label>
            <div style={{ marginLeft: '20px' }}>
              {[1, 2, 3, 4, 5].map(value => (
                <label key={value} style={{ marginLeft: '5px' }}>
                  <input
                    type="radio"
                    value={value}
                    {...register("work_quality_interventions", { required: "Please select a rating" })}
                    defaultChecked={rbtevalformDataval?.work_quality_interventions === value}
                  />
                  {value} - {["Poor", "NI", "Satisfactory", "Good", "Excellent"][value - 1]}
                </label>
              ))}
            </div>
            {errors.work_quality_interventions && <p style={{ color: 'red' }}>{errors.work_quality_interventions.message}</p>}
          </div>

          <div>
            <label style={{ display: 'block', fontWeight: 'bold' }}>
            a3. Employee submits complete case notes for clients in a timely manner (BCBA & BT).
            </label>
            <div style={{ marginLeft: '20px' }}>
              {[1, 2, 3, 4, 5].map(value => (
                <label key={value} style={{ marginLeft: '5px' }}>
                  <input
                    type="radio"
                    value={value}
                    defaultChecked={rbtevalformDataval?.work_quality_notes === value}
                    {...register("work_quality_notes", { required: "Please select a rating" })}
                  />
                  {value} - {["Poor", "NI", "Satisfactory", "Good", "Excellent"][value - 1]}
                </label>
              ))}
            </div>
            {errors.work_quality_notes && <p style={{ color: 'red' }}>{errors.work_quality_notes.message}</p>}
          </div>

          <div>
            <label style={{ display: 'block', fontWeight: 'bold' }}>
            a4. Employee produces well-written assessments for clients (BCBA).
            </label>
            <div style={{ marginLeft: '20px' }}>
              {[1, 2, 3, 4, 5].map(value => (
                <label key={value} style={{ marginLeft: '5px' }}>
                  <input
                    type="radio"
                    value={value}
                    {...register("work_quality_assessments", { required: "Please select a rating" })}
                    defaultChecked={rbtevalformDataval?.work_quality_assessments === value}
                  />
                  {value} - {["Poor", "NI", "Satisfactory", "Good", "Excellent"][value - 1]}
                </label>
              ))}
            </div>
            {errors.work_quality_assessments && <p style={{ color: 'red' }}>{errors.work_quality_assessments.message}</p>}
          </div>

          <div>
            <label style={{ display: 'block', fontWeight: 'bold' }}>
            a5. Employee collects and graphs data accurately after each treatment session (BCBA or BT).
            </label>
            <div style={{ marginLeft: '20px' }}>
              {[1, 2, 3, 4, 5].map(value => (
                <label key={value} style={{ marginLeft: '5px' }}>
                  <input
                    type="radio"
                    value={value}
                    {...register("work_quality_graph", { required: "Please select a rating" })}
                    defaultChecked={rbtevalformDataval?.work_quality_graph === value}
                  />
                  {value} - {["Poor", "NI", "Satisfactory", "Good", "Excellent"][value - 1]}
                </label>
              ))}
            </div>
            {errors.work_quality_graph && <p style={{ color: 'red' }}>{errors.work_quality_graph.message}</p>}
          </div>

          <div>
            <label style={{ display: 'block', fontWeight: 'bold' }}>
            a6. Employee reviews client data regularly to make informed treatment decision (BCBA or BT).
            </label>
            <div style={{ marginLeft: '20px' }}>
              {[1, 2, 3, 4, 5].map(value => (
                <label key={value} style={{ marginLeft: '5px' }}>
                  <input
                    type="radio"
                    value={value}
                    {...register("work_quality_review", { required: "Please select a rating" })}
                    defaultChecked={rbtevalformDataval?.work_quality_review === value}
                  />
                  {value} - {["Poor", "NI", "Satisfactory", "Good", "Excellent"][value - 1]}
                </label>
              ))}
            </div>
            {errors.work_quality_review && <p style={{ color: 'red' }}>{errors.work_quality_review.message}</p>}
          </div>
          <div>
            <label style={{ display: 'block', fontWeight: 'bold' }}>
            a7. Employee designs treatment interventions based on evidence-based research (BCBA).
            </label>
            <div style={{ marginLeft: '20px' }}>
              {[1, 2, 3, 4, 5].map(value => (
                <label key={value} style={{ marginLeft: '5px' }}>
                  <input
                    type="radio"
                    value={value}
                    {...register("work_quality_design", { required: "Please select a rating" })}
                    defaultChecked={rbtevalformDataval?.work_quality_design === value}
                  />
                  {value} - {["Poor", "NI", "Satisfactory", "Good", "Excellent"][value - 1]}
                </label>
              ))}
            </div>
            {errors.work_quality_design && <p style={{ color: 'red' }}>{errors.work_quality_design.message}</p>}
          </div>
          <div>
            <label style={{ display: 'block', fontWeight: 'bold' }}>
            a8. Employee follows ethical codes and agency policy when providing services (BCBA & BT).
            </label>
            <div style={{ marginLeft: '20px' }}>
              {[1, 2, 3, 4, 5].map(value => (
                <label key={value} style={{ marginLeft: '5px' }}>
                  <input
                    type="radio"
                    value={value}
                    {...register("work_quality_code", { required: "Please select a rating" })}
                    defaultChecked={rbtevalformDataval?.work_quality_code === value}
                  />
                  {value} - {["Poor", "NI", "Satisfactory", "Good", "Excellent"][value - 1]}
                </label>
              ))}
            </div>
            {errors.work_quality_code && <p style={{ color: 'red' }}>{errors.work_quality_code.message}</p>}
          </div>
          <div>
            <label style={{ display: 'block', fontWeight: 'bold' }}>
            a9. Employee manages challenging behavior and crisis situations appropriately (BCBA & BT).
            </label>
            <div style={{ marginLeft: '20px' }}>
              {[1, 2, 3, 4, 5].map(value => (
                <label key={value} style={{ marginLeft: '5px' }}>
                  <input
                    type="radio"
                    value={value}
                    {...register("work_quality_behavior", { required: "Please select a rating" })}
                    defaultChecked={rbtevalformDataval?.work_quality_behavior === value}
                  />
                  {value} - {["Poor", "NI", "Satisfactory", "Good", "Excellent"][value - 1]}
                </label>
              ))}
            </div>
            {errors.work_quality_behavior && <p style={{ color: 'red' }}>{errors.work_quality_behavior.message}</p>}
          </div>
          <div>
            <label style={{ display: 'block', fontWeight: 'bold' }}>
            a10. Employee formulates realistic short and long-term behavioral goals (BCBA).
            </label>
            <div style={{ marginLeft: '20px' }}>
              {[1, 2, 3, 4, 5].map(value => (
                <label key={value} style={{ marginLeft: '5px' }}>
                  <input
                    type="radio"
                    value={value}
                    {...register("work_quality_goal", { required: "Please select a rating" })}
                    defaultChecked={rbtevalformDataval?.work_quality_goal === value}
                  />
                  {value} - {["Poor", "NI", "Satisfactory", "Good", "Excellent"][value - 1]}
                </label>
              ))}
            </div>
            {errors.work_quality_goal && <p style={{ color: 'red' }}>{errors.work_quality_goal.message}</p>}
          </div>
          <div>
            <label style={{ display: 'block', fontWeight: 'bold' }}>
            a11. Employee formulates methods (process goals) for achieving an outcome (BCBA).
            </label>
            <div style={{ marginLeft: '20px' }}>
              {[1, 2, 3, 4, 5].map(value => (
                <label key={value} style={{ marginLeft: '5px' }}>
                  <input
                    type="radio"
                    value={value}
                    {...register("work_quality_outcome", { required: "Please select a rating" })}
                    defaultChecked={rbtevalformDataval?.work_quality_outcome === value}
                  />
                  {value} - {["Poor", "NI", "Satisfactory", "Good", "Excellent"][value - 1]}
                </label>
              ))}
            </div>
            {errors.work_quality_outcome && <p style={{ color: 'red' }}>{errors.work_quality_outcome.message}</p>}
          </div>
          <div>
            <label style={{ display: 'block', fontWeight: 'bold' }}>
            a12. Employee generates appropriate treatment recommendations based on the results of the assessment (BCBA).
            </label>
            <div style={{ marginLeft: '20px' }}>
              {[1, 2, 3, 4, 5].map(value => (
                <label key={value} style={{ marginLeft: '5px' }}>
                  <input
                    type="radio"
                    value={value}
                    {...register("work_quality_assessment", { required: "Please select a rating" })}
                    defaultChecked={rbtevalformDataval?.work_quality_assessment === value}
                  />
                  {value} - {["Poor", "NI", "Satisfactory", "Good", "Excellent"][value - 1]}
                </label>
              ))}
            </div>
            {errors.work_quality_assessment && <p style={{ color: 'red' }}>{errors.work_quality_assessment.message}</p>}
          </div>
          <div>
            <label style={{ display: 'block', fontWeight: 'bold' }}>
            a13. Employee conceptualizes and organizes data from definite theoretical view (BCBA).
            </label>
            <div style={{ marginLeft: '20px' }}>
              {[1, 2, 3, 4, 5].map(value => (
                <label key={value} style={{ marginLeft: '5px' }}>
                  <input
                    type="radio"
                    value={value}
                    {...register("work_quality_view", { required: "Please select a rating" })}
                    defaultChecked={rbtevalformDataval?.work_quality_view === value}
                  />
                  {value} - {["Poor", "NI", "Satisfactory", "Good", "Excellent"][value - 1]}
                </label>
              ))}
            </div>
            {errors.work_quality_view && <p style={{ color: 'red' }}>{errors.work_quality_view.message}</p>}
          </div>
          <div>
            <label style={{ display: 'block', fontWeight: 'bold' }}>
            a14. Employee is able to accurately integrate findings in a comprehensive report (BCBA).
            </label>
            <div style={{ marginLeft: '20px' }}>
              {[1, 2, 3, 4, 5].map(value => (
                <label key={value} style={{ marginLeft: '5px' }}>
                  <input
                    type="radio"
                    value={value}
                    {...register("work_quality_report", { required: "Please select a rating" })}
                    defaultChecked={rbtevalformDataval?.work_quality_report === value}
                  />
                  {value} - {["Poor", "NI", "Satisfactory", "Good", "Excellent"][value - 1]}
                </label>
              ))}
            </div>
            {errors.work_quality_report && <p style={{ color: 'red' }}>{errors.work_quality_report.message}</p>}
          </div>
          <div>
            <label style={{ display: 'block', fontWeight: 'bold' }}>
            a15. Employee is realistic in assessing and
re-assessing progress and revising formulation and diagnosis as indicated (BCBA).

            </label>
            <div style={{ marginLeft: '20px' }}>
              {[1, 2, 3, 4, 5].map(value => (
                <label key={value} style={{ marginLeft: '5px' }}>
                  <input
                    type="radio"
                    value={value}
                    {...register("work_quality_diagnosis", { required: "Please select a rating" })}
                    defaultChecked={rbtevalformDataval?.work_quality_diagnosis === value}
                  />
                  {value} - {["Poor", "NI", "Satisfactory", "Good", "Excellent"][value - 1]}
                </label>
              ))}
            </div>
            {errors.work_quality_diagnosis && <p style={{ color: 'red' }}>{errors.work_quality_diagnosis.message}</p>}
          </div>
          <div>
            <label style={{ display: 'block', fontWeight: 'bold' }}>
            a16. Employee uses effective collaborative strategies as evidenced by recognizing and understanding that as a member of an interdependent group, collaboration and compromise is required in order to maintain the effectiveness of the clinical team as a whole to effectively resolve problems (BCBA or BT).
            </label>
            <div style={{ marginLeft: '20px' }}>
              {[1, 2, 3, 4, 5].map(value => (
                <label key={value} style={{ marginLeft: '5px' }}>
                  <input
                    type="radio"
                    value={value}
                    {...register("work_quality_resolve", { required: "Please select a rating" })}
                    defaultChecked={rbtevalformDataval?.work_quality_resolve === value}
                  />
                  {value} - {["Poor", "NI", "Satisfactory", "Good", "Excellent"][value - 1]}
                </label>
              ))}
            </div>
            {errors.work_quality_resolve && <p style={{ color: 'red' }}>{errors.work_quality_resolve.message}</p>}
          </div>
      

          <div>
            <label>Comments:</label>
            <textarea
              {...register("work_quality_comments")}
              placeholder="Add any additional comments about the employee's performance"
              style={{ width: '100%', height: '100px' }}
              defaultValue={rbtevalformDataval?.work_quality_comments}
            />
          </div>
   {/* Performance Evaluation */}
   <h4>III.OVERALL ATTENDANCE AND PUNCTUALITY</h4>
          <div>
            <label style={{ display: 'block', fontWeight: 'bold' }}>
            a. Employee is on time for work (BCBA & BT).
            </label>
            <div style={{ marginLeft: '20px' }}>
              {[1, 2, 3, 4, 5].map(value => (
                <label key={value} style={{ marginLeft: '5px' }}>
                  <input
                    type="radio"
                    value={value}
                    {...register("attendance_time", { required: "Please select a rating" })}
                    defaultChecked={rbtevalformDataval?.attendance_time === value}
                  />
                  {value} - {["Poor", "NI", "Satisfactory", "Good", "Excellent"][value - 1]}
                </label>
              ))}
            </div>
            {errors.attendance_time && <p style={{ color: 'red' }}>{errors.attendance_time.message}</p>}
          </div>

          <div>
            <label style={{ display: 'block', fontWeight: 'bold' }}>
            b. Employee submits paperwork in a timely fashion to request time off (BCBA & BT).
            </label>
            <div style={{ marginLeft: '20px' }}>
              {[1, 2, 3, 4, 5].map(value => (
                <label key={value} style={{ marginLeft: '5px' }}>
                  <input
                    type="radio"
                    value={value}
                    {...register("attendance_submit", { required: "Please select a rating" })}
                    defaultChecked={rbtevalformDataval?.attendance_submit === value}
                  />
                  {value} - {["Poor", "NI", "Satisfactory", "Good", "Excellent"][value - 1]}
                </label>
              ))}
            </div>
            {errors.attendance_submit && <p style={{ color: 'red' }}>{errors.attendance_submit.message}</p>}
          </div>

      

          <div>
            <label>Comments:</label>
            <textarea
              {...register("attendance_comments")}
              placeholder="Add any additional comments about the employee's performance"
              style={{ width: '100%', height: '100px' }}
              defaultValue={rbtevalformDataval?.attendance_comments}
            />
          </div>
          <h4>IV. OVERALL INITIATIVE AND PROBLEM-SOLVING SKILLS</h4>
          <div>
            <label style={{ display: 'block', fontWeight: 'bold' }}>
            a. Employee shows initiative in working with clients, staff and key stakeholders to ensure the quality of treatment and service delivery (BCBA or BT).
            </label>
            <div style={{ marginLeft: '20px' }}>
              {[1, 2, 3, 4, 5].map(value => (
                <label key={value} style={{ marginLeft: '5px' }}>
                  <input
                    type="radio"
                    value={value}
                    {...register("initiative_stakeholder", { required: "Please select a rating" })}
                    defaultChecked={rbtevalformDataval?.initiative_stakeholder === value}
                  />
                  {value} - {["Poor", "NI", "Satisfactory", "Good", "Excellent"][value - 1]}
                </label>
              ))}
            </div>
            {errors.initiative_stakeholder && <p style={{ color: 'red' }}>{errors.initiative_stakeholder.message}</p>}
          </div>

          <div>
            <label style={{ display: 'block', fontWeight: 'bold' }}>
            b. Employee shows initiative in working with clients to ensure the quality of treatment delivery (BCBA or BT).
            </label>
            <div style={{ marginLeft: '20px' }}>
              {[1, 2, 3, 4, 5].map(value => (
                <label key={value} style={{ marginLeft: '5px' }}>
                  <input
                    type="radio"
                    value={value}
                    {...register("initiative_client", { required: "Please select a rating" })}
                    defaultChecked={rbtevalformDataval?.initiative_client === value}
                  />
                  {value} - {["Poor", "NI", "Satisfactory", "Good", "Excellent"][value - 1]}
                </label>
              ))}
            </div>
            {errors.initiative_client && <p style={{ color: 'red' }}>{errors.initiative_client.message}</p>}
          </div>

      

          <div>
            <label>Comments:</label>
            <textarea
              {...register("initiative_comments")}
              placeholder="Add any additional comments about the employee's performance"
              style={{ width: '100%', height: '100px' }}
              defaultValue={rbtevalformDataval?.initiative_comments}
            />
          </div>
          <h4>V. OVERALL COMMUNICATION / LISTENING SKILLS</h4>
          <div>
            <label style={{ display: 'block', fontWeight: 'bold' }}>
            a. Employee communicates effectively with clients and their families (BCBA or BT).
            </label>
            <div style={{ marginLeft: '20px' }}>
              {[1, 2, 3, 4, 5].map(value => (
                <label key={value} style={{ marginLeft: '5px' }}>
                  <input
                    type="radio"
                    value={value}
                    {...register("communication_client", { required: "Please select a rating" })}
                    defaultChecked={rbtevalformDataval?.communication_client === value}
                  />
                  {value} - {["Poor", "NI", "Satisfactory", "Good", "Excellent"][value - 1]}
                </label>
              ))}
            </div>
            {errors.communication_client && <p style={{ color: 'red' }}>{errors.communication_client.message}</p>}
          </div>

          <div>
            <label style={{ display: 'block', fontWeight: 'bold' }}>
            b. Employee communicates effectively with fellow peers in the same role (BCBA or BT).
            </label>
            <div style={{ marginLeft: '20px' }}>
              {[1, 2, 3, 4, 5].map(value => (
                <label key={value} style={{ marginLeft: '5px' }}>
                  <input
                    type="radio"
                    value={value}
                    {...register("communication_role", { required: "Please select a rating" })}
                    defaultChecked={rbtevalformDataval?.communication_role === value}
                  />
                  {value} - {["Poor", "NI", "Satisfactory", "Good", "Excellent"][value - 1]}
                </label>
              ))}
            </div>
            {errors.communication_role && <p style={{ color: 'red' }}>{errors.communication_role.message}</p>}
          </div>
          <div>
            <label style={{ display: 'block', fontWeight: 'bold' }}>
            c. Employee communicates concerns appropriately to supervisors (BCBA or BT).
            </label>
            <div style={{ marginLeft: '20px' }}>
              {[1, 2, 3, 4, 5].map(value => (
                <label key={value} style={{ marginLeft: '5px' }}>
                  <input
                    type="radio"
                    value={value}
                    {...register("communication_concern", { required: "Please select a rating" })}
                    defaultChecked={rbtevalformDataval?.communication_concern === value}
                  />
                  {value} - {["Poor", "NI", "Satisfactory", "Good", "Excellent"][value - 1]}
                </label>
              ))}
            </div>
            {errors.communication_concern && <p style={{ color: 'red' }}>{errors.communication_concern.message}</p>}
          </div>
          <div>
            <label style={{ display: 'block', fontWeight: 'bold' }}>
            d. Employee receives feedback from supervisors professionally (BCBA or BT).
            </label>
            <div style={{ marginLeft: '20px' }}>
              {[1, 2, 3, 4, 5].map(value => (
                <label key={value} style={{ marginLeft: '5px' }}>
                  <input
                    type="radio"
                    value={value}
                    {...register("communication_feedback", { required: "Please select a rating" })}
                    defaultChecked={rbtevalformDataval?.communication_feedback === value}
                  />
                  {value} - {["Poor", "NI", "Satisfactory", "Good", "Excellent"][value - 1]}
                </label>
              ))}
            </div>
            {errors.communication_feedback && <p style={{ color: 'red' }}>{errors.communication_feedback.message}</p>}
          </div>
          <div>
            <label style={{ display: 'block', fontWeight: 'bold' }}>
            e. Employee communicates with supervisor proactively to notify when falling below billable hours and makes efforts to engage in additional assignments to meet contractual obligations (BCBA or BT). 
            </label>
            <div style={{ marginLeft: '20px' }}>
              {[1, 2, 3, 4, 5].map(value => (
                <label key={value} style={{ marginLeft: '5px' }}>
                  <input
                    type="radio"
                    value={value}
                    {...register("communication_supervisor", { required: "Please select a rating" })}
                    defaultChecked={rbtevalformDataval?.communication_supervisor === value}
                  />
                  {value} - {["Poor", "NI", "Satisfactory", "Good", "Excellent"][value - 1]}
                </label>
              ))}
            </div>
            {errors.communication_supervisor && <p style={{ color: 'red' }}>{errors.communication_supervisor.message}</p>}
          </div>
          <div>
            <label style={{ display: 'block', fontWeight: 'bold' }}>
            f. Employee has professional and appropriate conversation with the families and staff (BCBA and BT). 
            </label>
            <div style={{ marginLeft: '20px' }}>
              {[1, 2, 3, 4, 5].map(value => (
                <label key={value} style={{ marginLeft: '5px' }}>
                  <input
                    type="radio"
                    value={value}
                    {...register("communication_professional", { required: "Please select a rating" })}
                    defaultChecked={rbtevalformDataval?.communication_professional === value}
                  />
                  {value} - {["Poor", "NI", "Satisfactory", "Good", "Excellent"][value - 1]}
                </label>
              ))}
            </div>
            {errors.communication_professional && <p style={{ color: 'red' }}>{errors.communication_professional.message}</p>}
          </div>
      

          <div>
            <label>Comments:</label>
            <textarea
              {...register("communication_comments")}
              placeholder="Add any additional comments about the employee's performance"
              style={{ width: '100%', height: '100px' }}
              defaultValue={rbtevalformDataval?.communication_comments}
            />
          </div>
          <h4>VI. OVERALL DEPENDABILITY</h4>
          <div>
            <label style={{ display: 'block', fontWeight: 'bold' }}>
            a. Employee is dependable in fulfilling his/her job duty for the agency (BCBA or BT).
            </label>
            <div style={{ marginLeft: '20px' }}>
              {[1, 2, 3, 4, 5].map(value => (
                <label key={value} style={{ marginLeft: '5px' }}>
                  <input
                    type="radio"
                    value={value}
                    {...register("dependability_job", { required: "Please select a rating" })}
                    defaultChecked={rbtevalformDataval?.dependability_job === value}
                  />
                  {value} - {["Poor", "NI", "Satisfactory", "Good", "Excellent"][value - 1]}
                </label>
              ))}
            </div>
            {errors.dependability_job && <p style={{ color: 'red' }}>{errors.dependability_job.message}</p>}
          </div>

          <div>
            <label style={{ display: 'block', fontWeight: 'bold' }}>
            b. Employee exercises good judgment when acting independently (BCBA or BT).
            </label>
            <div style={{ marginLeft: '20px' }}>
              {[1, 2, 3, 4, 5].map(value => (
                <label key={value} style={{ marginLeft: '5px' }}>
                  <input
                    type="radio"
                    value={value}
                    {...register("dependability_judgment", { required: "Please select a rating" })}
                    defaultChecked={rbtevalformDataval?.dependability_judgment === value}
                  />
                  {value} - {["Poor", "NI", "Satisfactory", "Good", "Excellent"][value - 1]}
                </label>
              ))}
            </div>
            {errors.dependability_judgment && <p style={{ color: 'red' }}>{errors.dependability_judgment.message}</p>}
          </div>

      

          <div>
            <label>Comments:</label>
            <textarea
              {...register("dependability_comments")}
              placeholder=""
              style={{ width: '100%', height: '100px' }}
              defaultValue={rbtevalformDataval?.dependability_comments}
            />
          </div>

          <div>
            <label>Overall Rating:</label>
            <textarea
              {...register("rating_comments", {
                required: "This field is required",
                minLength: {
                  value: 10,
                  message: "Minimum of 10 characters required"
                },
                maxLength: {
                  value: 1500,
                  message: "Maximum of 500 characters allowed"
                }
              })}
              placeholder="(Comments on Overall Evaluation - Narrative)"
              style={{ width: '100%', height: '100px' }}
              defaultValue={rbtevalformDataval?.rating_comments}
            />
              {errors.rating_comments && (
          <p style={{ color: "red" }}>{errors.rating_comments.message}</p>
        )}
          </div>
          <div>
            <label>STRENGTHS AND AREAS FOR DEVELOPMENT:</label>
            <textarea
              {...register("strength_development", {
                required: "This field is required",
                minLength: {
                  value: 10,
                  message: "Minimum of 10 characters required"
                },
                maxLength: {
                  value: 1500,
                  message: "Maximum of 500 characters allowed"
                }
              })}
              placeholder=""
              style={{ width: '100%', height: '100px' }}
              defaultValue={rbtevalformDataval?.strength_development}
            />
              {errors.strength_development && (
          <p style={{ color: "red" }}>{errors.strength_development.message}</p>
        )}
          </div>
          <div style={{ marginBottom: "10px" }}>
        <label>
          <input type="checkbox" {...register("goal_job")}  defaultChecked={rbtevalformDataval?.goal_job} />
          Review of employee's overall improvement in productivity/job knowledge
        </label>
      </div>

      <div style={{ marginBottom: "10px" }}>
        <label>
          <input type="checkbox" {...register("goal_quality")} defaultChecked={rbtevalformDataval?.goal_quality} />
          Review of employee's overall improvement in work quality
        </label>
      </div>

      <div style={{ marginBottom: "10px" }}>
        <label>
          <input type="checkbox" {...register("goal_punctuality")}  defaultChecked={rbtevalformDataval?.goal_punctuality}  />
          Review of employee's overall improvement in attendance and punctuality
        </label>
      </div>

      <div style={{ marginBottom: "10px" }}>
        <label>
          <input type="checkbox" {...register("goal_solving")}  defaultChecked={rbtevalformDataval?.goal_solving}/>
          Review of employee's overall improvement in initiative and problem-solving skills
        </label>
      </div>

      <div style={{ marginBottom: "10px" }}>
        <label>
          <input type="checkbox" {...register("goal_listening")}  defaultChecked={rbtevalformDataval?.goal_listening} />
          Review of employee's overall improvement in communication/listening skills
        </label>
      </div>

      <div style={{ marginBottom: "10px" }}>
        <label>
          <input type="checkbox" {...register("goal_dependability")} defaultChecked={rbtevalformDataval?.goal_dependability}/>
          Review of employee's overall improvement in work dependability
        </label>
      </div>
      {/* <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        <div style={{ width: '45%' }}>
          <label>Employee's Signature:</label>
          <input
            {...register("employeeSignature", { required: "Employee's signature is required" })}
            placeholder="Enter employee's signature"
            style={{ width: '100%' }}
          />
          {errors.employeeSignature && <p style={{ color: 'red' }}>{errors.employeeSignature.message}</p>}
        </div>

        <div style={{ width: '45%' }}>
          <label>Supervisor's Signature:</label>
          <input
            {...register("supervisorSignature", { required: "Supervisor's signature is required" })}
            placeholder="Enter supervisor's signature"
            style={{ width: '100%' }}
          />
          {errors.supervisorSignature && <p style={{ color: 'red' }}>{errors.supervisorSignature.message}</p>}
        </div>
      </div> */}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        <div style={{ width: '45%' }}>
          <label>Employee's Signature:</label>
          <input
            type="file"
            {...register("employeeSignature", { required: "Employee's signature is required" })}
            onChange={(e) => setEmployeeSignatureFile(e.target.files[0])}
            style={{ width: '100%' }}
            
          />
          {errors.employeeSignature && <p style={{ color: 'red' }}>{errors.employeeSignature.message}</p>}
          <p>{rbtevalformDataval?.employeeSignature}</p>
        </div>

        <div style={{ width: '45%' }}>
          <label>Supervisor's Signature:</label>
          <input
            type="file"
            {...register("supervisorSignature", { required: "Supervisor's signature is required" })}
            onChange={(e) => setSupervisorSignatureFile(e.target.files[0])}
            style={{ width: '100%' }}
           
           
          />
          {errors.supervisorSignature && <p style={{ color: 'red' }}>{errors.supervisorSignature.message}</p>}
          <p> {rbtevalformDataval?.supervisorSignature}</p>
        </div>
      </div>


      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        <div style={{ width: '45%' }}>
          <label>Employee's Name:</label>
          <input
            {...register("employee_sign_name", { required: "Employee's name is required" })}
            placeholder="Enter employee's name"
            style={{ width: '100%' }}
            // value={rbtevalformDataval.employee_sign_name}
            defaultValue={rbtevalformDataval?.employee_sign_name}
          />
          {errors.employee_sign_name && <p style={{ color: 'red' }}>{errors.employee_sign_name.message}</p>}
        </div>

        <div style={{ width: '45%' }}>
          <label>Supervisor's Name:</label>
          <input
            {...register("supervisor_sign_name", { required: "Supervisor's name is required" })}
            placeholder="Enter supervisor's name"
            style={{ width: '100%' }}
            defaultValue={rbtevalformDataval?.supervisor_sign_name}
            // value={rbtevalformDataval.supervisor_sign_name}
          />
          {errors.supervisor_sign_name && <p style={{ color: 'red' }}>{errors.supervisor_sign_name.message}</p>}
        </div>
      </div>
      
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        <div style={{ width: '45%' }}>
        <label>Employee Date:</label>
          <input
            type="date"
            {...register("employee_date", { required: "Date is required" })}
            style={{ width: '100%' }}
          
            defaultValue={rbtevalformDataval?.employee_date}
          />
          {errors.employee_date && <p style={{ color: 'red' }}>{errors.employee_date.message}</p>}
        </div>

        <div style={{ width: '45%' }}>
        <label>Supervisor Date:</label>
          <input
            type="date"
            {...register("supervisor_date", { required: "Date is required" })}
            style={{ width: '100%' }}
            defaultValue={rbtevalformDataval?.supervisor_date}
           
          />
          {errors.date && <p style={{ color: 'red' }}>{errors.supervisor_date.message}</p>}
        </div>
      </div>
          <p>Signing this form does not necessarily indicate that you agree with this evaluation. However, if you would like to make an appointment with your supervisor to discuss the out-come please feel welcome to do so. Please return at your earliest convenience.</p>
          {/* Submit Button */}
          {/* <button type="submit" style={{ backgroundColor: '#007bff', color: '#fff', padding: '10px', border: 'none', borderRadius: '5px' }}>
            Submit Evaluation
          </button> */}
        
      </Modal.Body>
      <Modal.Footer>
        {/* onClick={handleSubmit(onSubmit)} */}
        <button type="submit" className="btn btn-primary" onClick={handleSubmit(onSubmit)} >
          Save
        </button>
        <span className="btn btn-default" onClick={onClose}>Cancel</span>
      </Modal.Footer>

    </Modal>
    </form>

  );
};

export default RbtEvaluationForm;
