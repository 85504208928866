import React from 'react';
import { Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { Controller } from 'react-hook-form';

const IICPerformanceModal = ({ show, handleClose, handleSubmit, getValues10, register10, control10, errors10 }) => {
    return (
        <Modal show={show} onHide={handleClose} dialogClassName="custom-dialog">
            <Modal.Header closeButton>
                <Modal.Title>Employee Performance Evaluation (IIC,Psychotherapy)</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-i9">
                    <form key={3} onSubmit={handleSubmit} id="iic_candidate">

                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Employee Name and Title</label>
                                    <div style={{ display: 'flex', gap: '10px', alignItems: 'center', marginRight: "20px" }}>

                                        <input
                                            className="form-control text-box single-line"
                                            type="text"
                                            placeholder="Name"
                                            style={{ width: '100%' }}
                                            {...register10('employee_name')}
                                        />
                                        {errors10.employee_name?.message && (
                                            <p className='text-danger'>{errors10.employee_name.message}</p>
                                        )}

                                        
                                    </div>
                                    
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>
                                        Evaluation for the period of: 
                                    </label>
                                    <div className="d-flex align-items-start">
                                        {/* From Date Picker */}
                                        <div className="me-2 flex-grow-1 mr-2">
                                            <Controller
                                                control={control10}
                                                name="eval_period_from"
                                                render={({ field }) => (
                                                    <DatePicker
                                                        className="form-control text-box single-line"
                                                        placeholderText='Select date'
                                                        onChange={(date) => field.onChange(date)}
                                                        selected={field.value}
                                                        maxDate={new Date()}
                                                        dateFormat="MM/dd/yyyy"

                                                    />
                                                )}
                                            />
                                            {errors10.eval_period_from?.message && (
                                                <p className="text-danger">{errors10.eval_period_from.message}</p>
                                            )}
                                        </div>

                                        {/* To Date Picker */}
                                        <div className="flex-grow-1">
                                            <Controller
                                                control={control10}
                                                name="eval_period_to"
                                                 render={({ field }) => (
                                                    <DatePicker
                                                        className="form-control text-box single-line"
                                                        placeholderText='Select date'
                                                        onChange={(date) => field.onChange(date)}
                                                        selected={field.value}
                                                        maxDate={new Date()}
                                                        dateFormat="MM/dd/yyyy"

                                                    />
                                                )}
                                            />
                                            {errors10.eval_period_to?.message && (
                                                <p className="text-danger">{errors10.eval_period_to.message}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>





                        </div>
                        <div className="row">


                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Supervisor Name and Title</label>
                                    <div style={{ display: 'flex', gap: '10px', alignItems: 'center', marginRight: "20px" }}>

                                        <input
                                            className="form-control text-box single-line"
                                            type="text"
                                            placeholder="Name"
                                            style={{ width: '100%' }}
                                            {...register10('supervisor_name')}
                                        />
                                        {errors10.supervisor_name?.message && (
                                            <p className='text-danger'>{errors10.supervisor_name.message}</p>
                                        )}

                                       
                                    </div>
                                   
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Department</label>
                                    <input className="form-control text-box single-line" type="text"  {...register10('department')} />
                                    {errors10.department?.message && (
                                        <p className='text-danger'>{errors10.department}</p>
                                    )}
                                </div>
                            </div>



                        </div>
                        <hr />
                        <h6><b>GOALS AND OBJECTIVES DURING THIS EVALUATION PERIOD</b></h6>

                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px', width: '60%' }}>
                            <span style={{ marginRight: '8px' }}>1.</span>
                            <input
                                className="form-control text-box single-line"
                                type="text"
                                {...register10("goal_1")}
                                style={{ flexGrow: 1 }}
                            />
                        </div>

                        


                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px', width: '60%' }}>
                            <span style={{ marginRight: '8px' }}>2.</span>
                            <input
                                className="form-control text-box single-line"
                                type="text"
                                {...register10("goal_2")}
                                style={{ flexGrow: 1 }}
                            />
                        </div>


                        <div style={{ display: 'flex', alignItems: 'center', width: '60%' }}>
                            <span style={{ marginRight: '8px' }}>3.</span>
                            <input
                                className="form-control text-box single-line"
                                type="text"
                                {...register10("goal_3")}
                                style={{ flexGrow: 1 }}
                            />
                        </div>
                        <hr />
                        <h6><b>ACHIEVEMENTS, ACCOMPLISHMENTS, AND RESPONSIBILITIES (completed by employee)</b></h6>

                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px', width: '60%' }}>
                            <span style={{ marginRight: '8px' }}>1.</span>
                            <input
                                className="form-control text-box single-line"
                                type="text"
                                {...register10("achievements_1")}
                                style={{ flexGrow: 1 }}
                            />
                        </div>


                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px', width: '60%' }}>
                            <span style={{ marginRight: '8px' }}>2.</span>
                            <input
                                className="form-control text-box single-line"
                                type="text"
                                {...register10("achievements_2")}
                                style={{ flexGrow: 1 }}
                            />
                        </div>


                        <div style={{ display: 'flex', alignItems: 'center', width: '60%' }}>
                            <span style={{ marginRight: '8px' }}>3.</span>
                            <input
                                className="form-control text-box single-line"
                                type="text"
                                {...register10("achievements_3")}
                                style={{ flexGrow: 1 }}
                            />
                        </div>


                        <hr />



                        {/* Overall Job Knowledge */}
                        <table className="table table-bordered" style={{ width: '100%', borderCollapse: 'collapse' }}>
                            {/* Table Header */}
                            <thead>
                                <tr>
                                    <th style={{ padding: '8px', borderBottom: '1px solid #ddd' }}></th>
                                    {/* Separate headings for each rating option */}
                                    <th style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>1=Poor</th>
                                    <th style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>2=Fair</th>
                                    <th style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>3=Satisfactory</th>
                                    <th style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>4=Good</th>
                                    <th style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>5=Excellent</th>
                                    <th style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>6=NA</th>
                                </tr>

                            </thead>

                            <tbody>
                                <tr>
                                    <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                                        <label>
                                            <h6>Overall Job Knowledge </h6>
                                        </label>
                                    </td>

                                    {Array.from({ length: 6 }).map((_, index) => (
                                        <td style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }} key={index}>
                                            <div className="form-check">
                                                <input
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="job_knowledge"
                                                    value={index+1} // Adjust the value to match the backend (0-5)
                                                   defaultChecked={parseInt(getValues10("job_knowledge")) === index + 1} 
                                                    {...register10("job_knowledge")}
                                                />
                                            </div>
                                        </td>
                                    ))}
                                </tr>

                                <tr>
                                    <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                                        <label>
                                            Employee is knowledgeable about his/her role in mental therapy 
                                        </label>
                                    </td>

                                    {Array.from({ length: 6 }).map((_, index) => (
                                        <td style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }} key={index}>
                                            <div className="form-check">
                                                <input
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="job_knowledge_role"
                                                    value={index + 1} // Adjust the value to match the backend (0-5)
                                                    defaultChecked={parseInt(getValues10("job_knowledge")) === index + 1} 
                                                    {...register10("job_knowledge_role")}
                                                />
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <h6>Comments </h6>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <textarea rows="5" maxLength={255} className="form-control textarea" {...register10("job_knowledge_comment")}></textarea>
                                </div>
                            </div>
                        </div>
                        <hr />

                        {/* Overall Work Quality */}

                        <table className="table table-bordered" style={{ width: '100%', borderCollapse: 'collapse' }}>
                            {/* Table Header */}
                            <thead>
                                <tr>
                                    <th style={{ padding: '8px', borderBottom: '1px solid #ddd' }}></th>
                                    {/* Separate headings for each rating option */}
                                    <th style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>1=Poor</th>
                                    <th style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>2=Fair</th>
                                    <th style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>3=Satisfactory</th>
                                    <th style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>4=Good</th>
                                    <th style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>5=Excellent</th>
                                    <th style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>6=NA</th>
                                </tr>
                            </thead>

                            {/* Table Body */}
                            <tbody>
                                {/* Table Row 1 */}
                                <tr>
                                    <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                                        <label>
                                            <h6> Overall Work Quality </h6>
                                        </label>
                                    </td>
                                    {/* Radio buttons for each rating option */}
                                    {Array.from({ length: 6 }).map((_, index) => (
                                        <td style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }} key={index}>
                                            <div className="form-check">
                                                <input
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="work_quality"
                                                    value={index + 1}
                                                    defaultChecked={parseInt(getValues10("work_quality")) === index + 1}
                                                    {...register10("work_quality")}
                                                />
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                                        <label>
                                            Employee submits complete session notes for clients in a timely fashion. 
                                        </label>
                                    </td>
                                    {/* Radio buttons for each rating option */}
                                    {Array.from({ length: 6 }).map((_, index) => (
                                        <td style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }} key={index}>
                                            <div className="form-check">
                                                <input
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="work_quality_time"
                                                    value={index + 1}
                                                    defaultChecked={parseInt(getValues10("work_quality_time")) === index + 1}
                                                    {...register10("work_quality_time")}
                                                />
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                                        <label>
                                            Employee produces well written assessments/notes for clients. 
                                        </label>
                                    </td>
                                    {/* Radio buttons for each rating option */}
                                    {Array.from({ length: 6 }).map((_, index) => (
                                        <td style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }} key={index}>
                                            <div className="form-check">
                                                <input
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="work_quality_notes"
                                                    value={index + 1}
                                                    defaultChecked={parseInt(getValues10("work_quality_notes")) === index + 1}
                                                    {...register10("work_quality_notes")}
                                                />
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                                        <label>
                                            Employee develops treatment plans to accommodate the clients needs  
                                        </label>
                                    </td>
                                    {/* Radio buttons for each rating option */}
                                    {Array.from({ length: 6 }).map((_, index) => (
                                        <td style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }} key={index}>
                                            <div className="form-check">
                                                <input
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="work_quality_plans"
                                                    value={index + 1}
                                                    defaultChecked={parseInt(getValues10("work_quality_plans")) === index + 1}
                                                    {...register10("work_quality_plans")}
                                                />
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                                        <label>
                                            Employee designs treatment interventions based on evidence-based research.
                                        </label>
                                    </td>
                                    {/* Radio buttons for each rating option */}
                                    {Array.from({ length: 6 }).map((_, index) => (
                                        <td style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }} key={index}>
                                            <div className="form-check">
                                                <input
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="work_quality_design"
                                                    value={index + 1}
                                                    defaultChecked={parseInt(getValues10("work_quality_design")) === index + 1}
                                                    {...register10("work_quality_design")}
                                                />
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                                        <label>
                                            Employee follows ethical codes and agency policy when providing therapeutic services.
                                        </label>
                                    </td>
                                    {/* Radio buttons for each rating option */}
                                    {Array.from({ length: 6 }).map((_, index) => (
                                        <td style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }} key={index}>
                                            <div className="form-check">
                                                <input
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="work_quality_code"
                                                    value={index + 1}
                                                    defaultChecked={parseInt(getValues10("work_quality_code")) === index + 1}
                                                    {...register10("work_quality_code")}
                                                />
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                                        <label>
                                            Employee manages challenging behavior and crisis situations appropriately.
                                        </label>
                                    </td>
                                    {/* Radio buttons for each rating option */}
                                    {Array.from({ length: 6 }).map((_, index) => (
                                        <td style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }} key={index}>
                                            <div className="form-check">
                                                <input
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="work_quality_behavior"
                                                    value={index + 1}
                                                    defaultChecked={parseInt(getValues10("work_quality_behavior")) === index + 1}
                                                    {...register10("work_quality_behavior")}
                                                />
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <h6>Comments </h6>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <textarea rows="5" maxLength={255} className="form-control textarea" {...register10("work_quality_comments")}></textarea>
                                </div>
                            </div>
                        </div>
                        <hr />

                        {/* Overall attandance /punctuality */}

                        <table className="table table-bordered" style={{ width: '100%', borderCollapse: 'collapse' }}>
                            {/* Table Header */}
                            <thead>
                                <tr>
                                    <th style={{ padding: '8px', borderBottom: '1px solid #ddd' }}></th>
                                    {/* Separate headings for each rating option */}
                                    <th style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>1=Poor</th>
                                    <th style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>2=Fair</th>
                                    <th style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>3=Satisfactory</th>
                                    <th style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>4=Good</th>
                                    <th style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>5=Excellent</th>
                                    <th style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>6=NA</th>
                                </tr>
                            </thead>

                            {/* Table Body */}
                            <tbody>
                                {/* Table Row 1 */}
                                <tr>
                                    <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                                        <label>
                                            <h6>Overall Attendance/Punctuality </h6>
                                        </label>
                                    </td>
                                    {/* Radio buttons for each rating option */}
                                    {Array.from({ length: 6 }).map((_, index) => (
                                        <td style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }} key={index}>
                                            <div className="form-check">
                                                <input
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="attendance"
                                                    value={index + 1}
                                                    defaultChecked={parseInt(getValues10("attendance")) === index + 1}
                                                    {...register10("attendance")}
                                                />
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                                        <label>
                                            Employee is on time for therapy sessions 
                                        </label>
                                    </td>
                                    {/* Radio buttons for each rating option */}
                                    {Array.from({ length: 6 }).map((_, index) => (
                                        <td style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }} key={index}>
                                            <div className="form-check">
                                                <input
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="attendance_session"
                                                    value={index + 1}
                                                    defaultChecked={parseInt(getValues10("attendance_session")) === index + 1}
                                                    {...register10("attendance_session")}
                                                />
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <h6>Comments </h6>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <textarea rows="5" maxLength={255} className="form-control textarea" {...register10("attendance_comments")}></textarea>
                                </div>
                            </div>
                        </div>
                        <hr />

                        {/* Overall Initiative */}

                        <table className="table table-bordered" style={{ width: '100%', borderCollapse: 'collapse' }}>
                            {/* Table Header */}
                            <thead>
                                <tr>
                                    <th style={{ padding: '8px', borderBottom: '1px solid #ddd' }}></th>
                                    {/* Separate headings for each rating option */}
                                    <th style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>1=Poor</th>
                                    <th style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>2=Fair</th>
                                    <th style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>3=Satisfactory</th>
                                    <th style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>4=Good</th>
                                    <th style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>5=Excellent</th>
                                    <th style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>6=NA</th>
                                </tr>
                            </thead>

                            {/* Table Body */}
                            <tbody>
                                {/* Table Row 1 */}
                                <tr>
                                    <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                                        <label>
                                            <h6>Overall Initiative   </h6>
                                        </label>
                                    </td>
                                    {/* Radio buttons for each rating option */}
                                    {Array.from({ length: 6 }).map((_, index) => (
                                        <td style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }} key={index}>
                                            <div className="form-check">
                                                <input
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="initiative"
                                                    value={index + 1}
                                                    defaultChecked={parseInt(getValues10("initiative")) === index + 1}
                                                    {...register10("initiative")}
                                                />
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                                        <label>
                                            Employees show initiative in working with clients to ensure quality therapeutic care.  
                                        </label>
                                    </td>
                                    {/* Radio buttons for each rating option */}
                                    {Array.from({ length: 6 }).map((_, index) => (
                                        <td style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }} key={index}>
                                            <div className="form-check">
                                                <input
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="initiative_quality"
                                                    value={index + 1}
                                                    defaultChecked={parseInt(getValues10("initiative_quality")) === index + 1}
                                                    {...register10("initiative_quality")}
                                                />
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <h6>Comments </h6>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <textarea rows="5" maxLength={255} className="form-control textarea" {...register10("initiative_comments")}></textarea>
                                </div>
                            </div>
                        </div>
                        <hr />

                        {/* Overall communicative and listening skills */}
                        <table className="table table-bordered" style={{ width: '100%', borderCollapse: 'collapse' }}>
                            {/* Table Header */}
                            <thead>
                                <tr>
                                    <th style={{ padding: '8px', borderBottom: '1px solid #ddd' }}></th>
                                    {/* Separate headings for each rating option */}
                                    <th style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>1=Poor</th>
                                    <th style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>2=Fair</th>
                                    <th style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>3=Satisfactory</th>
                                    <th style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>4=Good</th>
                                    <th style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>5=Excellent</th>
                                    <th style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>6=NA</th>
                                </tr>
                            </thead>

                            {/* Table Body */}
                            <tbody>
                                {/* Table Row 1 */}
                                <tr>
                                    <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                                        <label>
                                            <h6>Overall Communication/Listening Skills</h6>
                                        </label>
                                    </td>
                                    {/* Radio buttons for each rating option */}
                                    {Array.from({ length: 6 }).map((_, index) => (
                                        <td style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }} key={index}>
                                            <div className="form-check">
                                                <input
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="communication_skill"
                                                    value={index + 1}
                                                    defaultChecked={parseInt(getValues10("communication_skill")) === index + 1}
                                                    {...register10("communication_skill")}
                                                />
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                                        <label>
                                            Employee communicates effectively with clients and their families. 
                                        </label>
                                    </td>
                                    {/* Radio buttons for each rating option */}
                                    {Array.from({ length: 6 }).map((_, index) => (
                                        <td style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }} key={index}>
                                            <div className="form-check">
                                                <input
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="communication_client"
                                                    value={index + 1}
                                                    defaultChecked={parseInt(getValues10("communication_client")) === index + 1}
                                                    {...register10("communication_client")}
                                                />
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                                        <label>
                                            Employee communicates effectively with fellow peers in the same role.
                                        </label>
                                    </td>
                                    {/* Radio buttons for each rating option */}
                                    {Array.from({ length: 6 }).map((_, index) => (
                                        <td style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }} key={index}>
                                            <div className="form-check">
                                                <input
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="communication_role"
                                                    value={index + 1}
                                                    defaultChecked={parseInt(getValues10("communication_role")) === index + 1}
                                                    {...register10("communication_role")}
                                                />
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                                        <label>
                                            Employee communicates concerns appropriately to supervisors.
                                        </label>
                                    </td>
                                    {/* Radio buttons for each rating option */}
                                    {Array.from({ length: 6 }).map((_, index) => (
                                        <td style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }} key={index}>
                                            <div className="form-check">
                                                <input
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="communication_concern"
                                                    value={index + 1}
                                                    defaultChecked={parseInt(getValues10("communication_concern")) === index + 1}
                                                    {...register10("communication_concern")}
                                                />
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                                        <label>
                                            Employee receives feedback from supervisors professionally.
                                        </label>
                                    </td>
                                    {/* Radio buttons for each rating option */}
                                    {Array.from({ length: 6 }).map((_, index) => (
                                        <td style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }} key={index}>
                                            <div className="form-check">
                                                <input
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="communication_feedback"
                                                    value={index + 1}
                                                    defaultChecked={parseInt(getValues10("communication_feedback")) === index + 1}
                                                    {...register10("communication_feedback")}
                                                />
                                            </div>
                                        </td>
                                    ))}
                                </tr>


                            </tbody>
                        </table>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <h6>Comments </h6>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <textarea rows="5" maxLength={255} className="form-control textarea" {...register10("communication_comments")}></textarea>
                                </div>
                            </div>
                        </div>
                        <hr />

                        {/* Overall Dependability */}

                        <table className="table table-bordered" style={{ width: '100%', borderCollapse: 'collapse' }}>
                            {/* Table Header */}
                            <thead>
                                <tr>
                                    <th style={{ padding: '8px', borderBottom: '1px solid #ddd' }}></th>
                                    {/* Separate headings for each rating option */}
                                    <th style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>1=Poor</th>
                                    <th style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>2=Fair</th>
                                    <th style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>3=Satisfactory</th>
                                    <th style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>4=Good</th>
                                    <th style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>5=Excellent</th>
                                    <th style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>6=NA</th>
                                </tr>
                            </thead>

                            {/* Table Body */}
                            <tbody>
                                {/* Table Row 1 */}
                                <tr>
                                    <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                                        <label>
                                            <h6>Overall Dependability. </h6>
                                        </label>
                                    </td>
                                    {/* Radio buttons for each rating option */}
                                    {Array.from({ length: 6 }).map((_, index) => (
                                        <td style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }} key={index}>
                                            <div className="form-check">
                                                <input
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="dependability"
                                                    value={index + 1}
                                                    defaultChecked ={parseInt(getValues10("dependability")) === index + 1}
                                                    {...register10("dependability")}
                                                />
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                                        <label>
                                            Employee is dependable in fulfilling his/her job duty for the agency. 
                                        </label>
                                    </td>
                                    {/* Radio buttons for each rating option */}
                                    {Array.from({ length: 6 }).map((_, index) => (
                                        <td style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }} key={index}>
                                            <div className="form-check">
                                                <input
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="dependability_job"
                                                    value={index + 1}
                                                    defaultChecked={parseInt(getValues10("dependability_job")) === index + 1}
                                                    {...register10("dependability_job")}
                                                />
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <h6>Comments </h6>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <textarea rows="5" maxLength={255} className="form-control textarea" {...register10("dependability_comments")}></textarea>
                                </div>
                            </div>
                        </div>
                        <hr />

                        {/* overall rating */}
                        <table className="table table-bordered" style={{ width: '100%', borderCollapse: 'collapse' }}>


                            {/* Table Body */}
                            <tbody>
                                {/* Table Row 1 */}
                                <tr>
                                    <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                                        <label>
                                            <h6>Overall Rating</h6>(average the rating numbers above)
                                        </label>
                                    </td>
                                    {/* Radio buttons for each rating option */}

                                    <td style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'center' }} >
                                        <input
                                            className="form-control text-box single-line"
                                            type="number"
                                            {...register10("rating")}
                                            style={{ flexGrow: 1 }}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <h6>Comments on Overall Evaluation (Narrative) </h6>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <textarea rows="5" maxLength={255} className="form-control textarea" {...register10("rating_comments")}></textarea>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <h6><b>STRENGTHS AND AREAS FOR DEVELOPMENT</b></h6>

                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px', width: '60%' }}>
                            <span style={{ marginRight: '8px' }}>1.</span>
                            <input
                                className="form-control text-box single-line"
                                type="text"
                                {...register10("strength_develop_1")}
                                style={{ flexGrow: 1 }}
                            />
                        </div>


                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px', width: '60%' }}>
                            <span style={{ marginRight: '8px' }}>2.</span>
                            <input
                                className="form-control text-box single-line"
                                type="text"
                                {...register10("strength_develop_2")}
                                style={{ flexGrow: 1 }}
                            />
                        </div>


                        <div style={{ display: 'flex', alignItems: 'center', width: '60%' }}>
                            <span style={{ marginRight: '8px' }}>3.</span>
                            <input
                                className="form-control text-box single-line"
                                type="text"
                                {...register10("strength_develop_3")}
                                style={{ flexGrow: 1 }}
                            />
                        </div>
                        <hr />
                        <h6><b>CAREER DEVELOPMENT PLAN</b></h6>

                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px', width: '60%' }}>
                            <span style={{ marginRight: '8px' }}>1.</span>
                            <input
                                className="form-control text-box single-line"
                                type="text"
                                {...register10("career_develop_1")}
                                style={{ flexGrow: 1 }}
                            />
                        </div>


                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px', width: '60%' }}>
                            <span style={{ marginRight: '8px' }}>2.</span>
                            <input
                                className="form-control text-box single-line"
                                type="text"
                                {...register10("career_develop_2")}
                                style={{ flexGrow: 1 }}
                            />
                        </div>


                        <div style={{ display: 'flex', alignItems: 'center', width: '60%' }}>
                            <span style={{ marginRight: '8px' }}>3.</span>
                            <input
                                className="form-control text-box single-line"
                                type="text"
                                {...register10("career_develop_3")}
                                style={{ flexGrow: 1 }}
                            />
                        </div>
                        <hr />
                        <h6><b>PROFESSIONAL DEVELOPMENT PLAN</b></h6>

                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px', width: '60%' }}>
                            <span style={{ marginRight: '8px' }}>1.</span>
                            <input
                                className="form-control text-box single-line"
                                type="text"
                                {...register10("professional_develop_1")}
                                style={{ flexGrow: 1 }}
                            />
                        </div>


                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px', width: '60%' }}>
                            <span style={{ marginRight: '8px' }}>2.</span>
                            <input
                                className="form-control text-box single-line"
                                type="text"
                                {...register10("professional_develop_2")}
                                style={{ flexGrow: 1 }}
                            />
                        </div>


                        <div style={{ display: 'flex', alignItems: 'center', width: '60%' }}>
                            <span style={{ marginRight: '8px' }}>3.</span>
                            <input
                                className="form-control text-box single-line"
                                type="text"
                                {...register10("professional_develop_3")}
                                style={{ flexGrow: 1 }}
                            />
                        </div>
                        <hr />
                        <h6><b>GOALS AND OBJECTIVES FOR NEXT EVALUATION PERIOD</b></h6>

                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px', width: '60%' }}>
                            <span style={{ marginRight: '8px' }}>1.</span>
                            <input
                                className="form-control text-box single-line"
                                type="text"
                                {...register10("goal_develop_1")}
                                style={{ flexGrow: 1 }}
                            />
                        </div>


                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px', width: '60%' }}>
                            <span style={{ marginRight: '8px' }}>2.</span>
                            <input
                                className="form-control text-box single-line"
                                type="text"
                                {...register10("goal_develop_2")}
                                style={{ flexGrow: 1 }}
                            />
                        </div>


                        <div style={{ display: 'flex', alignItems: 'center', width: '60%' }}>
                            <span style={{ marginRight: '8px' }}>3.</span>
                            <input
                                className="form-control text-box single-line"
                                type="text"
                                {...register10("goal_develop_3")}
                                style={{ flexGrow: 1 }}
                            />
                        </div>
                        <hr />
                        {/* signature */}
                        <h6><b>SIGNATURES</b></h6>
                        <table className="table table-bordered" style={{ width: '100%', borderCollapse: 'collapse' }} border="1">
                            <tr>
                                <th><label>Employee Signature:</label></th>
                                <td><input
                                    className="form-control text-box single-line"
                                    type="file"
                                    accept=".jpg,.jpeg,.png"
                                    {...register10('employee_sign', {
                                        validate: {
                                            fileupload: (files) => {
                                                const existingFile = getValues10('employee_sign');
                                                if (existingFile && files.length === 0) {
                                                    return true; 
                                                } if (files.length !== 0) {
                                                    if (files[0]?.size > 10000000) {
                                                        return "Allow only max 10MB.";
                                                    }
                                                    else if (!['image/jpeg', 'image/png'].includes(files[0]?.type)) {
                                                        return "Allow only jpg, jpeg, png formats.";
                                                    }
                                                }
                                            }
                                        }
                                    })}
                                /></td>
                                <th><label>Supervisor Signature:</label></th>
                                <td><input
                                    className="form-control text-box single-line"
                                    type="file"
                                    accept=".jpg,.jpeg,.png"
                                    {...register10('supervisor_sign', {
                                        
                                        validate: {
                                            fileupload: (files) => {
                                                const existingFile = getValues10('supervisor_sign');
                                                if (existingFile && files.length === 0) {
                                                    return true; 
                                                }
                                                if (files.length !== 0) {
                                                    if (files[0]?.size > 10000000) {
                                                        return "Allow only max 10MB.";
                                                    }
                                                    else if (!['image/jpeg', 'image/png'].includes(files[0]?.type)) {
                                                        return "Allow only jpg, jpeg, png formats.";
                                                    }
                                                }
                                            }
                                        }
                                    })}
                                /></td>
                            </tr>
                            <tr>
                                <th><label>Employee Name:</label></th>
                                <td><input
                                    className="form-control text-box single-line"
                                    type="text"
                                    placeholder="Name"
                                    style={{ width: '100%' }}
                                    {...register10('employee_sign_name')}
                                /></td>
                                <th><label>Supervisor Name:</label></th>
                                <td><input
                                    className="form-control text-box single-line"
                                    type="text"
                                    placeholder="Name"
                                    style={{ width: '100%' }}
                                    {...register10('supervisor_sign_name')}
                                /></td>
                            </tr>
                            <tr>
                                <th><label>Date:</label></th>
                                <td><Controller
                                    control={control10}
                                    name="employee_date"
                                    render={({ field }) => (
                                        <DatePicker
                                            className="form-control text-box single-line datetimepicker-input"
                                            placeholderText="Date"
                                            onChange={(date) => field.onChange(date)}
                                            selected={field.value}
                                            maxDate={new Date()}
                                            dateFormat="MM/dd/yyyy"
                                        />
                                    )}
                                />
                                    {errors10.eval_period_from?.message && (
                                        <p className="text-danger">{errors10.employee_date.message}</p>
                                    )}</td>
                                <th><label>Date:</label></th>
                                <td><Controller
                                    control={control10}
                                    name="supervisor_date"
                                    render={({ field }) => (
                                        <DatePicker
                                            className="form-control text-box single-line datetimepicker-input"
                                            placeholderText="Date"
                                            onChange={(date) => field.onChange(date)}
                                            selected={field.value}
                                            maxDate={new Date()}
                                            dateFormat="MM/dd/yyyy"
                                        />
                                    )}
                                />
                                    {errors10.eval_period_from?.message && (
                                        <p className="text-danger">{errors10.supervisor_date.message}</p>
                                    )}</td>
                            </tr>
                        </table>


                        <p>By signing this form, you confirm that you have discussed this review in detail with your supervisor. Signing this form does not necessarily indicate that you agree with this evaluation.</p>





                    </form>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="submit" className="btn btn-primary" form='iic_candidate'>Save</button>
                <span className="btn btn-default" onClick={handleClose}>Cancel</span>

            </Modal.Footer>
        </Modal>
    );
};

export default IICPerformanceModal;
