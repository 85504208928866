import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import { useForm, Controller } from 'react-hook-form';
import AdminHeader from '../layout/AdminHeader';
import AdminSidebar from '../layout/AdminSidebar';
import AdminFooter from '../layout/AdminFooter';
import Select from 'react-select';
import common from '../services/common'
import { Store } from 'react-notifications-component';
import Config from '../config/config';

const AddPortalUser = () => {

    var user_data = localStorage.getItem("user_data");
    var user_data_json = JSON.parse(user_data);
    const [dropdownData, setDropdownData] = useState(null);
    const [menuData, setMenuData] = useState(null);
    const [userrole, setUserrole] = useState(null);

    const [loading, setLoading] = useState(false);

    const [dropDownOpt, setdropDownOpt] = useState([]);
    const [menuOptData, setMenuOptData] = useState([]);

    const [loginError, setLoginError] = useState();
    const navigate = useNavigate();
    const [allowDirectDeposit, setAllowDirectDeposit] = useState(0); // Default to 0 (Disabled)

    const {
        control,
        register,
        getValues,
        handleSubmit,
        setValue,
        reset,
        formState: { errors }
    } = useForm();



    const onSubmit = async (data) => {
        setLoading(true);
        if (data.position_id != null) {
            let position_id_m = data.position_id.map(a => a.value);
            data.position_id = position_id_m;
        }
        var AddPortalUserapi = await common.AddorupdatePortalUser(data);
        if (AddPortalUserapi?.data?.status == 400) {
            navigate('/user/logout');
            return;
        }
        if (AddPortalUserapi?.data?.status == 202) {
            Store.addNotification({
                title: "Success!",
                message: AddPortalUserapi?.data?.message,
                type: "success",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });
            navigate('/admin/portal-users', { state: { success: '1' } });
            setLoading(false);
        }
        else {
            Store.addNotification({
                title: "Error!",
                message: AddPortalUserapi?.data?.message,
                type: "danger",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });
            setLoading(false);
        }
    }
    const handleDirectDepositChange = (event) => {
        console.log("start")
        const value = event.target.value;
        setAllowDirectDeposit(value); // Update state based on selection
    };
// const PasswordInput = ({ name, label, value, onChange, error }) => {};
const [showPassword, setShowPassword] = useState(false);

const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
};

    useEffect(() => {

        clearInterval(Config.intervalId);
        (async () => {


            if (!['1','2','3'].includes(user_data_json.role_id)) {
                navigate('/user/login');
                return;
            }


            var dropdownvalue = await common.getdropdown(null);



            setDropdownData(dropdownvalue.data.data)

            const dropDownValuedata = dropdownvalue.data.data.position_field_2.map((response) => ({
                "value": response.id,
                "label": response.name
            }))

            setdropDownOpt(dropDownValuedata);

            var menuvalue = await common.getmenus(null);

           

            setMenuData(menuvalue.data.data)

            const menuOptValuedata = menuvalue.data.data.menus.map((response) => ({
                "value": response.id,
                "label": response.name
            }))

            setMenuOptData(menuOptValuedata);

            console.log("menuValue..,", menuOptData)

        })();

    }, []);
    return (

        <div className="adminview wrapper">
            <AdminHeader />
            <AdminSidebar />

            <div className="content-wrapper px-3 pt-1 pb-3">
                <div className="content-header">
                    <div className="row">
                        <div className="col-sm-6 pl-0">
                            <h1 className="m-0">
                                Add New Portal User
                                <small>
                                    <NavLink to="/admin/portal-users"><i className="fas fa-arrow-circle-left"></i> back to portal user list</NavLink>
                                </small>
                            </h1>
                        </div>
                        <div className="col-sm-6 pr-0">
                            <div className="float-right">
                                <button type="submit" className="btn btn-primary" form="add_user" >
                                    <i className="far fa-save"></i> Save {loading ? <span className="spinner-grow spinner-grow-sm"></span> : ''}
                                </button>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="form-horizontal">

                    <div className="card card-secondary card-outline" data-card-name="customer-info" id="customer-info">
                        <div className="card-header with-border clearfix">
                            <div className="card-title"><i className="fas fa-info"></i>Portal User info</div>
                            <div className="card-tools float-right">
                                <button className="btn btn-tool" data-card-widget="collapse" type="button">
                                    <i className="fa toggle-icon fa-minus"></i>
                                </button>
                            </div>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)} id="add_user">
                            <input type="hidden"  {...register("user_id")} value={0} />
                            <div className="card-body">
                                <div className="row">


                                    <div className="col-md-6">
                                        <div className="form-group row">
                                            <div className="col-md-3">
                                                <div className="label-wrapper">
                                                    <label className="col-form-label">First Name <span className="required">*</span></label>
                                                </div>
                                            </div>
                                            <div className="col-md-9">
                                                <input className="form-control text-box single-line" type="text"  {...register("first_name", { required: "Please enter first name." })} />
                                                {errors.first_name?.message && (
                                                    <p className='text-danger'>{errors.first_name.message}</p>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group row">
                                            <div className="col-md-3">
                                                <div className="label-wrapper">
                                                    <label className="col-form-label">Last Name <span className="required">*</span></label>
                                                </div>
                                            </div>
                                            <div className="col-md-9">
                                                <input className="form-control text-box single-line" type="text"  {...register("last_name", { required: "Please enter last name." })} />
                                                {errors.last_name?.message && (
                                                    <p className='text-danger'>{errors.last_name.message}</p>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group row">
                                            <div className="col-md-3">
                                                <div className="label-wrapper">
                                                    <label className="col-form-label">Phone <span className="required">*</span></label>
                                                </div>
                                            </div>
                                            <div className="col-md-9">
                                                <input className="form-control text-box single-line" type="text" {...register("phone", { required: "Please enter cell phone number." })} />
                                                {errors.phone?.message && (
                                                    <p className='text-danger'>{errors.phone.message}</p>
                                                )}

                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group row">
                                            <div className="col-md-3">
                                                <div className="label-wrapper">
                                                    <label className="col-form-label">Email <span className="required">*</span></label>
                                                </div>
                                            </div>
                                            <div className="col-md-9">
                                                <input className="form-control text-box single-line" type="text"  {...register("email", {
                                                    required: "Please enter email address.",
                                                    validate: {
                                                        maxLength: (v) =>
                                                            v.length <= 50 || "The email should have at most 50 characters",
                                                        matchPattern: (v) =>
                                                            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                                                            "Please enter a valid email address",
                                                    },
                                                })} />
                                                {errors.email?.message && (
                                                    <p className='text-danger'>{errors.email.message}</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group row">
                                            <div className="col-md-3">
                                                <div className="label-wrapper">
                                                    <label className="col-form-label">Password <span className="required">*</span></label>
                                                </div>
                                            </div>
                                            <div className="col-md-9">

                                                <input className="form-control text-box single-line password" type="password"  {...register("password", { required: "Please enter password." })} />
                                                {errors.password?.message && (
                                                    <p className='text-danger'>{errors.password.message}</p>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group row">
                                            <div className="col-md-3">
                                                <div className="label-wrapper">
                                                    <label className="col-form-label">Portal User Role <span className="required">*</span></label>
                                                </div>
                                            </div>
                                            <div className="col-md-9">
                                                <select className="form-control" onClick={(event) => {
                                                    // here set target value to state which is 0, 1, 2, 3
                                                    //  alert(event.target.value);
                                                    setUserrole(event.target.value);
                                                }} {...register('role_id', { required: "Please select user role." })}>
                                                    <option value="">Please Select</option>
                                                    {dropdownData?.role_field && dropdownData.role_field.filter(pf => pf.id <= 3).map((pf, key) => {


                                                        return <option value={pf.id}>{pf.name}</option>


                                                    })}
                                                </select>
                                                {errors.role_id?.message && (
                                                    <p className='text-danger'>{errors.role_id.message}</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">



                                        {userrole != 1 && <div className="form-group row">
                                            <div className="col-md-3">
                                                <div className="label-wrapper">
                                                    <label className="col-form-label">Access Position <span className="required">*</span></label>
                                                </div>
                                            </div>
                                            <div className="col-md-9">






                                                <Controller
                                                    name="position_id"
                                                    control={control}
                                                    rules={{ required: "Please select access position." }}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            options={dropDownOpt}
                                                            defaultValue={1}
                                                            isMulti
                                                        />
                                                    )}
                                                />



                                                {errors.position_id?.message && (
                                                    <p className='text-danger'>{errors.position_id.message}</p>
                                                )}





                                            </div>
                                        </div>
                                        }



                                    </div>




                                    <div className="col-md-6">
                                        <div className="form-group row">
                                            <div className="col-md-3">
                                                <div className="label-wrapper">
                                                    <label className="col-form-label">Status <span className="required">*</span></label>
                                                </div>
                                            </div>
                                            <div className="col-md-9">
                                                <select className="form-control" {...register('status', { required: "Please select status." })}>
                                                    <option value="">Please Select</option>
                                                    <option value={1}>Enabled</option>
                                                    <option value={0}>Disabled</option>
                                                </select>
                                                {errors.status?.message && (
                                                    <p className='text-danger'>{errors.status.message}</p>
                                                )}

                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group row">
                                            <div className="col-md-3">
                                                <div className="label-wrapper">
                                                    <label className="col-form-label">
                                                        Show Direct Deposit <span className="required">*</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-9">
                                                <select
                                                    className="form-control"
                                                    {...register('allow_direct_deposit', { required: "Please select Direct Deposit." })}
                                                    onChange={handleDirectDepositChange} // Update state on change
                                                >
                                                    <option value={0}>Disabled</option>
                                                    <option value={1}>Enabled</option>
                                                </select>
                                                {errors.allow_direct_deposit && (
                                                    <p className='text-danger'>{errors.allow_direct_deposit.message}</p>
                                                )}
                                            </div>
                                        </div>
                                        {(allowDirectDeposit === '1' ) && (
                                            <div className="form-group row">
                                                <div className="col-md-3">
                                                    <div className="label-wrapper">
                                                        <label className="col-form-label">
                                                            Security Password <span className="required">*</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-md-9">
                                                    <div className="input-group">
                                                        <input
                                                            type={showPassword ? 'text' : 'password'}
                                                            className="form-control"
                                                            placeholder="Enter Password"

                                                            {...register('deposit_password', { required: "Password is required" })}
                                                        />
                                                        <div className="input-group-append">
                                                            <button
                                                                className="btn btn-outline-secondary"
                                                                type="button"
                                                                onClick={togglePasswordVisibility}
                                                            >
                                                                {showPassword ? <i class="nav-icon fas fa-eye-slash" ></i> : <i class="nav-icon fas fa-eye"></i>}
                                                            </button>
                                                        </div>
                                                        {errors.deposit_password && (
                                                            <p className='text-danger'>{errors.deposit_password.message}</p>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    {/* Access menu options for staff and hr  */}
                                    <div className="col-md-6">
                                        {userrole != 1 && (
                                            <div className="form-group row">
                                                <div className="col-md-3">
                                                    <div className="label-wrapper">
                                                        <label className="col-form-label">
                                                            Access Menu <span className="required">*</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-md-9">
                                                    {/* Checkbox group */}
                                                    <Controller
                                                        name="menu_id"
                                                        control={control}
                                                        defaultValue={[1, 3, 5]}
                                                        render={({ field }) => (
                                                            <div>
                                                                {menuOptData.map((option) => (
                                                                    <div key={option.value} className="form-check">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="form-check-input"
                                                                            id={`accessMenu${option.value}`}
                                                                            value={option.value}
                                                                            checked={
                                                                                field.value.includes(option.value) ||
                                                                                option.value === 1 || option.value === 3 ||
                                                                                option.value === 5
                                                                            }
                                                                            onChange={(e) => {
                                                                                const { checked, value } = e.target;
                                                                                const numberValue = parseInt(value, 10);

                                                                                if (checked) {

                                                                                    field.onChange([...new Set([...field.value, numberValue])]);
                                                                                } else {

                                                                                    if (numberValue !== 1 && numberValue !== 3 && numberValue !== 5) {
                                                                                        field.onChange(field.value.filter((item) => item !== numberValue));
                                                                                    }
                                                                                }
                                                                            }}
                                                                            disabled={option.value === 1 || option.value === 3 || option.value === 5}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={`accessMenu${option.value}`}>
                                                                            {option.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                    />

                                                    {/* Error message */}
                                                    {errors.menu_id?.message && (
                                                        <p className="text-danger">{errors.menu_id.message}</p>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>


                                </div>



                            </div>
                        </form>
                    </div>
                </div>





                <div id="delete-confirmation" className="modal fade" tabindex="-1" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Are you sure?</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="form-horizontal">
                                <div className="modal-body">
                                    <p>Are you sure you want to delete this portal user?</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="submit" className="btn btn-danger">Delete</button>
                                    <span className="btn btn-default" data-dismiss="modal">Cancel</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <AdminFooter />
        </div>

    );
};

export default AddPortalUser
