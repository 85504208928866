import React,{useState,useEffect} from 'react';
import {NavLink,useNavigate,useLocation,useParams  } from "react-router-dom";
 

 

const AdminSidebar = () => {


    const [authCheck, setAuthCheck] = useState()
    const navigate = useNavigate();
    useEffect(() => {
      
     
       
        var user_data=localStorage.getItem("user_data");     
        var user_data_json=JSON.parse(user_data);

       console.log("login data " , user_data_json.menu);
         
        
     if(user_data==undefined || user_data==null  )
     {
        navigate('/user/login');
        return;
     }
     setAuthCheck(user_data_json);

     
    }, []);

    console.log("menus" , authCheck?.menu.includes('2'))
  return (

    <aside className="main-sidebar sidebar-dark-primary elevation-1">
            <NavLink className="brand-link logo-switch" to="/admin/dashboard">
                <img src="/logo-xs.png?v=1" alt="Logo Small" className="brand-image-xl logo-xs" />
                <img src="/Poss logo-01.png?v=1" alt="Logo Large" style={{width : '111px'}} className="brand-image-xs logo-xl" />
            </NavLink>
            <div className="sidebar">
                <nav className="mt-3">
                    <ul className="nav nav-pills nav-sidebar nav-child-indent" data-widget="treeview" role="menu">



                    


                    {(authCheck?.role_id==4)  &&   <li className="nav-item">
                      <NavLink className="nav-link" to="/user/dashboard">
                                <i className="nav-icon fa fa-home"></i>
                                <p>
                                Edit Application
                                </p>
                        </NavLink>
                        </li>}

                    {(authCheck?.role_id==4)  &&   <li className="nav-item">
                      <NavLink className="nav-link" to="/user/documents">
                                <i className="nav-icon fa fa-file"></i>
                                <p>
                                Required Documents
                                </p>
                        </NavLink>
                        </li>}


                    {(authCheck?.role_id==1 || authCheck?.role_id==2 || authCheck?.role_id==3)  &&   <li className="nav-item">
                      <NavLink className="nav-link" to="/admin/dashboard">
                                <i className="nav-icon fa fa-home"></i>
                                <p>
                                    Dashboard
                                </p>
                        </NavLink>
                        </li>}



                      {((authCheck?.role_id == 1) || (authCheck?.menu.includes('2')) ) && <li className="nav-item">
                            
                            <NavLink className="nav-link" to="/admin/portal-users">
                                <i className="nav-icon fa fa-user"></i>
                                <p>
                                    Portal Users 
                                </p>
                            </NavLink>


                            <NavLink className="add-new-link" to="/admin/add-portal-user"><i className="fas fa-plus"></i></NavLink>
                        </li>}

                        {authCheck?.role_id==1 && <li className="nav-item">
                            
                            


                            {/* <NavLink className="add-new-link" to="/admin/add-portal-user"><i className="fas fa-plus"></i></NavLink> */}
                        </li>}
                        {(authCheck?.role_id==1 || authCheck?.role_id==2 || authCheck?.role_id==3)  && <li className="nav-item">
                            <NavLink className="nav-link" to="/admin/applications">
                                <i className="nav-icon fas fa-users"></i>
                                <p>
                                Candidates 
                                </p>
                            </NavLink>
                            {/* <a href="#" className="add-new-link"><i className="fas fa-plus"></i></a> */}
                        </li>}
                      {(authCheck?.role_id == 1 || (authCheck?.role_id == 2 && authCheck?.menu.includes('4')) || (authCheck?.role_id == 3 && authCheck?.menu.includes('4'))) && <li className="nav-item">
                          <NavLink className="nav-link" to="/admin/employees">
                              <i className="nav-icon fas fa-suitcase"></i>
                              <p>
                                  Employees
                              </p>
                          </NavLink>
                      </li>}
                     {/*  <li className="nav-item">
                            <NavLink className="nav-link" to="/admin/messages">
                                <i className="nav-icon fas fa-comment-dots"></i>
                                <p>
                                Messages
                                </p>
                            </NavLink>
  </li>*/}
                        {(authCheck?.role_id==1 || authCheck?.role_id==2 || authCheck?.role_id==3)  &&  <li className="nav-item">
                            {/* <NavLink className="nav-link" to="/admin/position-pipeline">
                                <i className="nav-icon fa fa-list"></i>
                                <p>
                                    Pipeline 
                                </p>
                            </NavLink> */}
                            <NavLink className="nav-link" to="/admin/edit-profile">
                                <i className="nav-icon fas fa-edit"></i>
                                <p>
                                    Edit Profile
                                </p>
                            </NavLink>
                        </li>}


                        {((authCheck?.role_id==1) || (authCheck?.menu.includes('6')))  &&  <li className="nav-item">
                            <NavLink className="nav-link" to="/admin/location">
                                <i className="nav-icon fas fa-location"></i>
                                <p>
                                    Location
                                </p>
                            </NavLink>
                        </li>}

                        {(authCheck?.allow_direct_deposit==1)  &&  <li className="nav-item">
                            <NavLink className="nav-link" to="/admin/direct-deposit">
                                <i className="nav-icon fas fa-pen"></i>
                                <p>
                                    Direct Deposit
                                </p>
                            </NavLink>
                        </li>}

                        {((authCheck?.role_id==1) ||  (authCheck?.menu.includes('7')))  &&  <li className="nav-item">
                            <NavLink className="nav-link" to="/admin/position">
                                <i className="nav-icon fas fa-list"></i>
                                <p>
                                    Position
                                </p>
                            </NavLink>
                        </li>}

                       {(((authCheck?.role_id==1) || (authCheck?.role_id==4)) ||  (authCheck?.menu.includes('8')))  && <li className="nav-item">
                            <NavLink className="nav-link" to="/admin/pay-increase">
                                <i className="nav-icon fas fa-dollar"></i>
                                <p>
                                    Pay Increase
                                </p>
                            </NavLink>
                        </li>}
                    </ul>
                </nav>
            </div>
        </aside>

  );
};

export default AdminSidebar;
