import React, { useState,useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap'
import axios from 'axios';
import Config from '../config/config';
import {  useParams } from "react-router-dom";
import {NavLink,useNavigate} from "react-router-dom";
import { Store } from 'react-notifications-component';
import editapplication from './EditApplication'
import common from '../services/common'

const ChildAbusesecFile = ({userDataval,setchildjercyupdateDataval,childjercyupdateDataval,formDataval, ddshow,onClose,setformDataval}) => {
  const params = useParams();
  const navigate = useNavigate();

  if(userDataval?.role_id!=4)
    var candidate_id_main = params.id;
  else
  var candidate_id_main =userDataval?.candidate_id;
  const [formData, setFormData] = useState({
    applicant_name: '',
    employee_name: '',
    receipt_date: '',
    received_by: '',
    applicant_date: '',
    contact_no: '',
    abuse_investigation: '',
    employment_sepration: '',
    license_revocation: '',
    employee_sign: '',
    employee_title: '',
    date: ''
  });
  const [showModal, setShowModal] = useState(ddshow); // Modal state
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

    useEffect(() => {
        setShowModal(ddshow);
      
    },
    [ddshow])
    useEffect (() =>
    {
      console.log("formdataval",formDataval)
      setFormData(formDataval)
    },[formDataval])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   // handle form submission
  //   console.log('Form Data:', formData);
  // };
  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault();

    if (validateForm()) {
      try {
        var user_data = localStorage.getItem("user_data");
        var user_data_json = JSON.parse(user_data);
        var authheaders = {
          "Content-Type": "text/json",
          "Authorization": "Bearer " + user_data_json?.token
  
        }
              formData.candidate_detail_id=candidate_id_main
        const response = await axios.post(Config.API_URL + 'api/add-jersey-abuse-discloser',formData,{
          headers: authheaders
        });
        console.log('Form submitted successfully:', response.data);
        
        onClose(); // Close the modal after successful submission
        if (response?.data?.status == 202) {
          Store.addNotification({
              title: "Success!",
              message: response?.data?.message,
              type: "success",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                  duration: 3000,
                  onScreen: true
              }
          });
          //navigate('/admin/portal-users',{state:{success:'1'}});
          // window.location.reload();
          setLoading(false);
          setchildjercyupdateDataval(2)
     
          return;
        }
        else{
          Store.addNotification({
            title: "Error!",
            message: response?.data?.message,
            type: "danger",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 3000,
                onScreen: true
            }
        });
        }
      } catch (error) {
        
          navigate('/user/logout');
          return;
      
      
        console.error('Error submitting form:', error);
      }
    }
  };
  const validateForm = () => {
    const newErrors = {};
    // Basic validation rules (you can add more specific checks if needed)
    if (!formData?.applicant_name) newErrors.applicant_name = 'Applicant name is required';
    if (!formData?.employee_name) newErrors.employee_name = 'Employer name is required';
    if (!formData?.receipt_date) newErrors.receipt_date = 'Receipt date is required';
    if (!formData?.contact_no) newErrors.contact_no = 'Contact phone number is required';
    if (!formData?.abuse_investigation && formData?.abuse_investigation!= "0" && formData?.abuse_investigation!= "1" ) newErrors.abuse_investigation = 'This field is required';
    if (!formData?.employment_sepration  && formData?.employment_sepration!= "0" && formData?.employment_sepration!= "1" ) newErrors.employment_sepration = 'This field is required';
    if (!formData?.license_revocation  && formData?.license_revocation!= "0" && formData?.license_revocation!= "1" ) newErrors.license_revocation = 'This field is required';
    if (!formData?.employee_sign) newErrors.employee_sign = 'Employer signature is required';
    if (!formData?.employee_title) newErrors.employee_title = 'Employer title is required';
    if (!formData?.date) newErrors.date = 'Date signed is required';
    if (!formData?.received_by) newErrors.received_by = 'Received by is required';
    if (!formData?.applicant_date) newErrors.applicant_date = 'Employment dates are required';



    setErrors(newErrors);
    setLoading(false)
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  return (
    <Modal show={showModal} onHide={onClose} dialogClassName="custom-dialog">
    <Modal.Header closeButton>
        <Modal.Title> <h4>New Jersey Sexual Misconduct/Child Abuse Disclosure Release  </h4></Modal.Title>
    </Modal.Header>
    <Modal.Body>
    <form onSubmit={handleSubmit}>
    

      <div className="form-group"   style={{ marginTop:"40px" }}>
        <label>Applicant's Name:</label>
        <input
          type="text"
          name="applicant_name"
          value={formData?.applicant_name}
          onChange={handleChange}
          style={{ marginLeft:"180px",marginBottom:"20px" }}
        />
                    {errors.applicant_name && <p style={{ color: 'red' }}>{errors.applicant_name}</p>}

      </div>

      <div className="form-group">
        <label>Employer's Name:</label>
        <input
          type="text"
          name="employee_name"
          value={formData?.employee_name}
          onChange={handleChange}
          style={{ marginLeft:"180px",marginBottom:"20px" }}
        />
                               {errors.employee_name && <p style={{ color: 'red' }}>{errors.employee_name}</p>}


      </div>

      <div className="form-group">
        <label>Receipt Date:</label>
        <input
          type="date"
          name="receipt_date"
          value={formData?.receipt_date}
          onChange={handleChange}
          style={{ marginLeft:"205px",marginBottom:"20px" }}
        />
                    {errors.receipt_date && <p style={{ color: 'red' }}>{errors.receipt_date}</p>}

      </div>

      <div className="form-group">
        <label>Received By (Hiring Entity):</label>
        <input
          type="text"
          name="received_by"
          value={formData?.received_by}
          onChange={handleChange}
          style={{ marginLeft:"135px",marginBottom:"20px" }}
        />
                    {errors.received_by && <p style={{ color: 'red' }}>{errors.received_by}</p>}

      </div>

      <div className="form-group">
        <label>Applicant's Dates of Employment:</label>
        <input
          type="date"
          name="applicant_date"
          value={formData?.applicant_date}
          onChange={handleChange}
          style={{ marginLeft:"99px",marginBottom:"20px" }}
        />
                    {errors.applicant_date && <p style={{ color: 'red' }}>{errors.applicant_date}</p>}

      </div>

      <div className="form-group">
        <label>Contact Phone Number:</label>
        <input
          type="tel"
          name="contact_no"
          value={formData?.contact_no}
          onChange={handleChange}
          style={{ marginLeft:"149px",marginBottom:"20px" }}
        />
                    {errors.contact_no && <p style={{ color: 'red' }}>{errors.contact_no}</p>}

      </div>
      <div style={{marginTop:"20px" , marginBottom:"20px"}}>
        <label>
        Been the subject of any child abuse or sexual misconduct investigation by any employer,
State licensing agency, law enforcement agency, or the Department of Children and Families (*unless the
investigation resulted in a finding that the allegations were false or the alleged incident of child abuse or
sexual misconduct was not substantiated)?
        </label>
        <label style={{marginLeft:"30px"}}>
          <input
            type="radio"
            name="abuse_investigation"
            value="1"
            checked={formData?.abuse_investigation == "1"}
            onChange={handleChange}
            required
          />{" "}
          Yes
        </label>
        <label style={{marginLeft:"30px"}}>
          <input
            type="radio"
            name="abuse_investigation"
            value="0"
            checked={formData?.abuse_investigation == "0"}
            onChange={handleChange}
            required
          />{" "}
          No
        </label>
        {errors.abuse_investigation && <p style={{ color: 'red' }}>{errors.abuse_investigation}</p>}

      </div>


      <div>
        <label>
        Been disciplined, discharged, non-renewed, asked to resign from employment, resigned
from or otherwise separated from any employment while allegations of child abuse or sexual misconduct
we’re pending or under investigation, or due to an adjudication or finding of child abuse or sexual
        </label>
        <label style={{marginLeft:"30px"}}>
          <input
            type="radio"
            name="employment_sepration"
            value="1"
            checked={formData?.employment_sepration == "1"}
            onChange={handleChange}
            required
          />{" "}
          Yes
        </label>
        <label style={{marginLeft:"30px"}}>
          <input
            type="radio"
            name="employment_sepration"
            value="0"
            checked={formData?.employment_sepration == "0"}
            onChange={handleChange}
            required
          />{" "}
          No
        </label>
        {errors.employment_sepration && <p style={{ color: 'red' }}>{errors.employment_sepration}</p>}
      </div>

      <div>
        <label>
        Had a license, professional license, or certificate suspended, surrendered, or revoked while
allegations of child abuse or sexual misconduct were pending or under investigation, or due to an
adjudication or finding of child abuse or sexual misconduct?
        </label>
        <label style={{marginLeft:"30px"}}>
          <input
            type="radio"
            name="license_revocation"
            value="1"
            checked={formData?.license_revocation == "1"}
            onChange={handleChange}
            required
          />{" "}
          Yes
        </label>
        <label style={{marginLeft:"30px"}}>
          <input
            type="radio"
            name="license_revocation"
            value="0"
            checked={formData?.license_revocation == "0"}
            onChange={handleChange}
            required
          />{" "}
          No
        </label>
        {errors.license_revocation && <p style={{ color: 'red' }}>{errors.license_revocation}</p>}

       
      </div>

      <h3>Employer Verification</h3>
      <p>
        If any of the above disclosure questions are answered "Yes," additional
        information may be requested. Complete the section below:
      </p>

      <div>
        <label >Current/Former Employer Representative Signature:</label>
        <input
          type="text"
          name="employee_sign"
          value={formData?.employee_sign}
          onChange={handleChange}
          style={{ marginLeft:"39px",marginBottom:"20px" }}
        />
              
                    {errors.employee_sign && <p style={{ color: 'red' }}>{errors.employee_sign}</p>}

      </div>

      <div>
        <label >Employer Representative Title:</label>
        <input
          type="text"
          name="employee_title"
          value={formData?.employee_title}
          onChange={handleChange}
          style={{ marginLeft:"149px",marginBottom:"20px" }}
        />
                    

            {errors.employee_title && <p style={{ color: 'red' }}>{errors.employee_title}</p>}
      </div>

      <div>
        <label>Date Signed:</label>
        <input
          type="date"
          name="date"
          value={formData?.date}
          onChange={handleChange}
          style={{ marginLeft:"239px",marginBottom:"20px" }}
        />
            {errors.date  && <p style={{ color: 'red' }}>{errors.date }</p>}
      </div>

     

      <p> 
      If a current or former employer responds to any Section 2 disclosure in the affirmative, the hiring entity may 
request additional information regarding the disclosure by requesting that the current or former employer 
complete the Sexual Misconduct/Child Abuse Disclosure Information Request form within 20 days and attach 
additional information, including the initial complaint and final report, if any, regarding the incident of child abuse 
or sexual misconduct. Pursuant to N.J.S.A. 18A:6-7.11, a current or former employer that provides information or 
records about a current or former employee or applicant shall be immune from criminal and civil liability for the 
disclosure of the information, unless the information or records provided were knowingly false. The immunity 
shall be in addition to, and not in limitation of, any other immunity provided by law.
  </p>

      <p> 
      The failure of a current or former employer to provide the information requested in Section 2 within the 20-day 
timeframe required by N.J.S.A. 18A:6-7.9 may be grounds for the automatic disqualification of an applicant from 
employment with the hiring entity. The hiring entity shall not be liable for any claims brought by an applicant who 
is not offered employment or whose employment is terminated: (1) because of any information received by the 
hiring entity from an employer pursuant to N.J.S.A. 18A:6-7.7; or (2) due to the inability of the hiring entity to 
conduct a full review of the applicant’s employment history pursuant to N.J.S.A. 18A:6-7.7.

      </p>
    </form>
    </Modal.Body>
    <Modal.Footer>
        <button type="button" className="btn btn-primary" form='directdeposit_candidate'  onClick={handleSubmit}  >Save</button>
        <span className="btn btn-default" onClick={onClose} >Cancel</span>

    </Modal.Footer>
</Modal>
  );
};

export default ChildAbusesecFile;
